var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardCard',{attrs:{"id":"dashboard-availabilities-card","loading":_vm.loading,"expanded":_vm.expanded,"widget-type":_vm.widgetType,"title":"New Availabilities","count":_vm.total},on:{"update:expanded":function($event){return _vm.$emit('update:expanded', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-switch',{staticClass:"ml-5 mr-5",attrs:{"dense":"","hide-details":"","label":"In House Only","loading":_vm.filterLoading},model:{value:(_vm.inHouse),callback:function ($$v) {_vm.inHouse=$$v},expression:"inHouse"}}),(_vm.defaultStartDate)?_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.defaultStartDate))+" - "+_vm._s(_vm._f("formatDate")(_vm.endDate)))]):_c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":_vm.$vuetify.breakpoint.xs}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v(" From: "+_vm._s(_vm._f("formatDate")(_vm.dateStart))+" ")],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}})],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":_vm.$vuetify.breakpoint.xs}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar")]),_vm._v("To: "+_vm._s(_vm._f("formatDate")(_vm.endDate))+" ")],1)]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}})],1)],1),_c('v-select',{staticClass:"ml-1",staticStyle:{"max-width":"200px"},attrs:{"outlined":"","label":"Select Property Type","dense":"","hide-details":"","items":_vm.propertyTypes,"loading":_vm.loading,"clearable":""},on:{"change":_vm.fetchAvailabilities},model:{value:(_vm.propertyType),callback:function ($$v) {_vm.propertyType=$$v},expression:"propertyType"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.loading}},[_c('BaseTable',{attrs:{"table-options-entry":_vm.tableOptions,"total-rows-entry":_vm.total,"headers-data-entry":_vm.headers,"items-entry":_vm.items,"loading":_vm.filterLoading},on:{"options:change":function($event){_vm.tableOptions = $event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('ClientContent',{attrs:{"props":item,"allow-select":false,"active-account-id":_vm.activeAccountId}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }