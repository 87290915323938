import { ContactRolesEnum } from '@/bundles/Contact/enums/contactRoles';
import i18n from '@/bundles/Localization';
import { sprinklerTypes } from '@/bundles/Properties/helpers';
import { getPropertyCenterTypeTextValues } from '@/bundles/Property/enums/PropertyCenterTypeEnum';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';
import { railLines } from '@/bundles/Filters/consts/railLines';

export const availableFilters = {
  property_type: 'select',
  construction_status: 'autocomplete',
  building_size: 'range',
  land_size: 'range',
  total_size: 'range',
  occupier_company: 'string',
  include_occupier_children: 'boolean',
  occupancy_type: 'select',
  occupier_size: 'range',
  property_clear_height: 'building_dimension_range'
};

export const expandedFiltersName = {
  property_type: 'Property Type',
  building_size: 'Building Size',
  land_size: 'Land Size',
  total_size: 'Total Size',
  construction_status: 'Construction Status',
  occupier_company: 'Occupier Company',
  include_occupier_children: 'Include Child Companies',
  occupancy_type: 'Occupancy Type',
  occupier_size: 'Occupier Size',
};

export const autocompleteItems = {
  construction_status: [
    { text: 'Proposed' },
    { text: 'Under Construction' },
    { text: 'Existing' },
  ],
};

export const selectItems = {
  property_type: [],
  occupancy_type: [
    { text: 'Owner Occupied', value: 'ownerOccupied' },
    { text: 'Leased', value: 'leased' },
  ],
};

export const getGlobalFilters = () => ({
  group: 'properties',
  model: false,
  isActive: false,
  icon: 'mdi-domain',
  title: 'Properties',
  children: [
    {
      icon: 'mdi-adjust',
      title: 'Property Type',
      items: [],
      name: 'property_type',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Primary Use',
      items: [],
      name: 'property_primary_use',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Use Type(s)',
      name: 'property_use_type',
      type: 'use_type_filter',
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.retail_center_type),
      items: getPropertyCenterTypeTextValues(),
      name: 'property_center_type',
      type: 'select',
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Property Size',
          name: 'property_size',
          type: 'range'
        },
        {
          title: 'Property Land Size',
          name: 'property_land_size',
          type: 'range'
        },
        {
          name: 'property_land_size_unit',
          type: 'helper'
        }
      ],
      meta: {
        title: 'Property Size',
        landTitle: 'Property Land Size'
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Construction Status',
      items: [
        { text: 'Proposed', value: 'proposed' },
        { text: 'Under Construction', value: 'under construction' },
        { text: 'Existing', value: 'existing' },
      ],
      name: 'property_construction_status',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.target_completion_year),
      items: ['', ''],
      name: 'property_target_completion_year',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Sprinkler Type',
      items: sprinklerTypes,
      name: 'property_sprinkler_type',
      type: 'select',
    },
    {
      type: 'clear_height',
      title: 'Clear Height',
      children: [
        {
          title: 'Min Clear Height',
          type: 'building_dimension_range',
          name: 'property_min_clear_height',
        },
        {
          title: 'Max Clear Height',
          type: 'building_dimension_range',
          name: 'property_max_clear_height'
        },
      ]
    },
    {
      icon: 'mdi-adjust',
      title: '# Dock High Doors',
      items: ['', ''],
      name: 'property_dock_door_count',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: '# Drive-In Doors',
      items: ['', ''],
      name: 'property_ground_door_count',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Building Class',
      items: ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-'],
      name: 'property_building_class',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Rail'
    },
    {
      icon: 'mdi-adjust',
      title: 'Rail Status',
      items: ['Active', 'In Place', 'Available', 'Unavailable'],
      name: 'property_rail_status',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Rail Line',
      items: railLines,
      name: 'property_rail_line',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Market & Location'
    },
    {
      icon: 'mdi-adjust',
      title: 'City',
      name: 'property_location_city',
      type: 'city_combobox',
      class: 'mt-0 pa-0'
    },
    {
      icon: 'mdi-adjust',
      title: 'State',
      name: 'property_location_state',
      type: 'states_combobox',
    },
    {
      icon: 'mdi-adjust',
      title: 'Zip / Postal Code',
      name: 'property_location_postal_cd',
      type: 'postal_code_combobox',
    },
    {
      icon: null,
      title: null,
      items: [],
      name: 'property_market',
      type: 'dynamic_market',
      children: [
        {
          icon: 'mdi-adjust',
          title: `Property ${i18n.t(LocalizationEnum.apn_number)}`,
          items: ['', ''],
          name: 'property_apn_number',
          type: 'textfield',
        },
      ]
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Company & Contact'
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Name',
      name: 'company_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Name',
      name: 'contact_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Company Role',
      items: [
        { text: 'Agent', value: ContactRolesEnum.agent },
        { text: 'Contractor', value: ContactRolesEnum.contractor },
        { text: 'Employee', value: ContactRolesEnum.employee },
      ],
      name: 'contact_company_role',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Property Role',
      items: [
        { text: 'Architect', value: ContactRolesEnum.architect },
        { text: 'Developer', value: ContactRolesEnum.developer },
        { text: 'Listing Agent', value: ContactRolesEnum.listing_agent },
        {
          text: 'Listing Agent - Sublease',
          value: ContactRolesEnum['listing_agent_-_sublease'],
        },
        { text: 'Owner', value: ContactRolesEnum.owner },
        { text: 'Property Manager', value: ContactRolesEnum.property_manager },
        { text: 'Structural Engineer', value: ContactRolesEnum.structural_engineer },
        { text: 'True Owner', value: ContactRolesEnum.true_owner },
        { text: 'Previous Owner', value: ContactRolesEnum.previous_owner },
      ],
      name: 'property_contact_role',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'property_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'property_view',
      type: 'create_updated_select',
    },
    {
      title: 'Filtered Properties',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
