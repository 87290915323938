import mixins from 'vue-typed-mixins';
import { RecordsCardMixin } from '@/bundles/Common/mixins/RecordsCardMixin';
import { mapState } from 'vuex';
import { PropType } from 'vue';
import { IProperty } from '@/bundles/Property/interfaces/IProperty';
import { Nullable } from '@/utils/types';
import { formatPercent, formatRawNumber } from '@/utils/filters';

interface IAvailabilitiesCardMixinData {
  showLeaseAndSold: boolean;
}

export const AvailabilitiesCardMixin = mixins(RecordsCardMixin).extend({
  props: {
    property: {
      type: Object as PropType<IProperty>,
      default: () => ({} as IProperty),
    }
  },

  data: (): IAvailabilitiesCardMixinData => ({
    showLeaseAndSold: false,
  }),

  computed: {
    ...mapState({
      activeAccountId: (state: any) => state.activeAccount?.id
    }),
    leasedPercentage (): Nullable<string> {
      if (!('size' in this.property)) {
        return null;
      }

      if (!this.items.length) {
        return '100%';
      }

      const total = formatRawNumber(this.property.size.building.rentable);

      if (!total) {
        return '100%';
      }

      const itemsAvailable = this.items.filter(item => ['Active', 'Pending'].includes(item.status));
      const availableSpace = itemsAvailable.reduce((acc, availability) => {
        return acc + formatRawNumber(availability.available_space);
      }, 0);

      const occupied = total - availableSpace;
      return formatPercent(occupied / total * 100);
    },
  },
});
