


































import mixins from 'vue-typed-mixins';
import { DialogMixin } from '@/bundles/Dialogs/mixins/DialogMixin';

export default mixins(DialogMixin).extend({
  name: 'ConfidentialConfirmDialog',

  props: {
    confidential: Boolean,
    loading: Boolean,
    updateRelatedText: {
      type: String,
      default: 'Update related deals',
    },
    source: {
      type: String,
      default: 'deal',
    },
  },

  data: (): { updateRelated: boolean } => ({
    updateRelated: false,
  }),

  methods: {
    cancelHandler () {
      this.$emit('cancel');
      this.showDialog = false;
    },
    updateHandler () {
      this.$emit('update', {
        confidential: this.confidential,
        updateRelated: this.updateRelated
      });
    },
  }
});
