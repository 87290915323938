

























import Vue from 'vue';

export default Vue.extend({
  name: 'ConfidentialCheckbox',

  props: {
    confidential: Boolean,
    loading: Boolean,
  },

  methods: {
    updateHandler (value: boolean) {
      this.$emit('update', value);
    },
  }
});
