import { IUseTypes } from '@/bundles/Property/interfaces/useTypes/IUseTypes';

const officeUseTypes = [
  { text: 'Commercial Condo' },
  { text: 'Coworking' },
  { text: 'Creative Office' },
  { text: 'Executive Suite' },
  { text: 'Medical Office' },
  { text: 'R&D' },
  { text: 'Storage' }
]

const industrialUseTypes = [
  { text: 'Aerospace and Aviation' },
  { text: 'Cannabis' },
  { text: 'Cold Storage' },
  { text: 'Creative Office' },
  { text: 'Data Center' },
  { text: 'Food and Beverage Processing' },
  { text: 'Freight Forwarding' },
  { text: 'Light Industrial' },
  { text: 'Manufacturing' },
  { text: 'R&D' },
  { text: 'Service' },
  { text: 'Showroom' },
  { text: 'Truck Terminal' },
  { text: 'Distribution-Warehouse' },
  { text: 'Transload' },
];

const landUseTypes = [
  { text: 'Agriculture' },
  { text: 'Development' },
  { text: 'Storage Yard' },
  { text: 'Parking Lot' },
  { text: 'Ranch' },
  { text: 'Residential' },
  { text: 'Vineyard/ Winery' }
];

const retailUseTypes = [
  { text: 'Automotive' },
  { text: 'Bank' },
  { text: 'Banking and Financial' },
  { text: 'Bar / Nightclub' },
  { text: 'Clothing and Apparel' },
  { text: 'Convenience Store' },
  { text: 'Department Store' },
  { text: 'Electronics Stores' },
  { text: 'Gas Station' },
  { text: 'Greenhouse/ Nursery' },
  { text: 'Restaurant' },
  { text: 'Grocery Anchored' },
  { text: 'Indoor Shopping Center' }
];

const multifamilyUseTypes = [
  { text: 'Apartments' },
  { text: 'Student Housing' },
  { text: 'Manufactured Housing' },
  { text: 'Senior Living' }
];

const healthcareUseTypes = [
  { text: 'Assisted Living' },
  { text: 'Clinic' },
  { text: 'Dental' },
  { text: 'Hospice' },
  { text: 'Hospital' },
  { text: 'Imaging Center' },
  { text: 'Medical Office' },
  { text: 'Rehabitilization Center' },
  { text: 'Senior Care/ Nursing Home' },
  { text: 'Urgent Care' }
];

const hospitalityUseTypes = [
  { text: 'Boutique Hotel' },
  { text: 'Extended Stay Hotel' },
  { text: 'Hostel' },
  { text: 'Hotel' },
  { text: 'Inns' },
  { text: 'Luxury Hotel' },
  { text: 'Motel' },
  { text: 'Resort' }
];

const specialPurposeUseTypes = [
  { text: 'Airport/ Transportation Center' },
  { text: 'Cultural Center' },
  { text: 'Education' },
  { text: 'Government' },
  { text: 'Higher Education' },
  { text: 'Library' },
  { text: 'Museum' },
  { text: 'Religious' },
  { text: 'Amusement Park' },
  { text: 'Concert Hall' },
  { text: 'Gaming and Casino' },
  { text: 'Golf Course' },
  { text: 'Recreation / Sports Park' },
  { text: 'Stadium / Sporting Venue' },
  { text: 'Theatre / Cinema' },
  { text: 'Theme Park' },
  { text: 'Business For Sale' },
  { text: 'Horse Property' }
];

export function getUniqueSortedUseTypesByType (): IUseTypes[] {
  const allUseTypes = [
    { 'header': 'Industrial Use Types' },
    ...industrialUseTypes,
    { 'header': 'Office Use Types' },
    ...officeUseTypes,
    { 'header': 'Retail Use Types' },
    ...retailUseTypes,
    { 'header': 'Multifamily Use Types' },
    ...multifamilyUseTypes,
    { 'header': 'Land Use Types' },
    ...landUseTypes,
    { 'header': 'Healthcare Use Types' },
    ...healthcareUseTypes,
    { 'header': 'Hospitality Use Types' },
    ...hospitalityUseTypes,
    { 'header': 'Special Purpose Use Types' },
    ...specialPurposeUseTypes,
  ];

  return allUseTypes;
}

export function getAllUseTypes (): IUseTypes[] {
  const allUseTypes = [
    ...officeUseTypes,
    ...industrialUseTypes,
    ...landUseTypes,
    ...retailUseTypes,
    ...multifamilyUseTypes,
    ...healthcareUseTypes,
    ...hospitalityUseTypes,
    ...specialPurposeUseTypes,
  ];

  function compare (a: { text: string }, b: { text: string }) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }

  return allUseTypes.sort(compare);
}
