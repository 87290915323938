import * as companyFilters from './company';
import * as contactFilters from './contact';
import * as occupierFilters from './occupier';
import * as requirementFilters from './requirement';
import * as availabilityFilters from './availability';
import * as propertyFilters from './property';
import * as comparableFilters from './comparable';
import * as pipelineFilters from './pipeline';
import * as memberFilters from './member';
import * as requestFilters from './request';
import * as savedSetFilters from './savedSet';
import * as eblastFilters from './eblast';
import * as pressReleaseFilters from './pressRelease';
import * as projectFilters from './project';
import * as dealFilters from './deal';

export const filtersConfig = {
  company: companyFilters,
  contact: contactFilters,
  occupier: occupierFilters,
  requirement: requirementFilters,
  availability: availabilityFilters,
  property: propertyFilters,
  comparable: comparableFilters,
  pipeline: pipelineFilters,
  member: memberFilters,
  request: requestFilters,
  savedSet: savedSetFilters,
  eblast: eblastFilters,
  pressRelease: pressReleaseFilters,
  project: projectFilters,
  deal: dealFilters
};
