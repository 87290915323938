import { IComparable, TransactionTypeEnum } from '@/bundles/Comparables/types';
import { Nullable } from '@/utils/types';

interface IComparableCompaniesInfo {
  buyerTenantLabel: string;
  buyerTenantValue: string;
  sellerLandlordLabel: string;
  sellerLandlordValue: string;
}

const getContactName = (contact: Nullable<Record<string, any>>): string => {
  if (!contact) return '';

  return `${contact.first_name} ${contact.last_name}`;
}

const getSaleCompaniesInfo = (comparable: IComparable): IComparableCompaniesInfo => {
  const buyerCompany = Array.isArray(comparable.companies) ? comparable.companies.filter(company => company.type === 'buyer')?.[0] : comparable.companies.buyer;
  const sellerCompany = Array.isArray(comparable.companies) ? comparable.companies.filter(company => company.type === 'seller')?.[0] : comparable.companies.seller;
  const buyerContact = Array.isArray(comparable.contacts) ? comparable.contacts.filter(contact => contact.type === 'buyer')?.[0] : comparable.contacts.buyer?.[0];
  const sellerContact = Array.isArray(comparable.contacts) ? comparable.contacts.filter(contact => contact.type === 'seller')?.[0] : comparable.contacts.seller?.[0];

  const buyerLabel = (buyerCompany?.name || buyerContact?.company?.name || getContactName(buyerContact)).trim();
  const sellerLabel = (sellerCompany?.name || sellerContact?.company?.name || getContactName(sellerContact)).trim();

  return {
    buyerTenantLabel: 'Buyer',
    buyerTenantValue: buyerLabel,
    sellerLandlordLabel: 'Seller',
    sellerLandlordValue: sellerLabel,
  };
}

const getLeaseSubleaseCompaniesInfo = (comparable: IComparable, isSublease: boolean) => {
  const tenantCompany = Array.isArray(comparable.companies) ? comparable.companies.filter(company => company.type === 'tenant')?.[0] : comparable.companies.tenant;
  const landlordCompany = Array.isArray(comparable.companies) ? comparable.companies.filter(company => company.type === 'landlord')?.[0] : comparable.companies.landlord;
  const tenantContact = Array.isArray(comparable.contacts) ? comparable.contacts.filter(contact => contact.type === 'tenant')?.[0] : comparable.contacts.tenant?.[0];
  const landlordContact = Array.isArray(comparable.contacts) ? comparable.contacts.filter(contact => contact.type === 'landlord')?.[0] : comparable.contacts.landlord?.[0];

  const tenantLabel = (tenantCompany?.name || tenantContact?.company?.name || getContactName(tenantContact)).trim();
  const landlordLabel = (landlordCompany?.name || landlordContact?.company?.name || getContactName(landlordContact)).trim();

  return {
    buyerTenantLabel: isSublease ? 'Sublessee' : 'Tenant',
    buyerTenantValue: tenantLabel,
    sellerLandlordLabel: isSublease ? 'Sublessor' : 'Landlord',
    sellerLandlordValue: landlordLabel,
  };
}

const getLeaseCompaniesInfo = (comparable: IComparable) => {
  return getLeaseSubleaseCompaniesInfo(comparable, comparable.transaction.sublease);
}

export const getComparableCompaniesInfo = (comparable: IComparable): IComparableCompaniesInfo => {
  const transactionType = comparable.transaction_type || comparable.transaction?.transaction_type;
  if (transactionType === TransactionTypeEnum.sale) {
    return getSaleCompaniesInfo(comparable);
  }

  return getLeaseCompaniesInfo(comparable);
}
