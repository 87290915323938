import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { BuildingDimensionMeasurementUnits, SpaceMeasurementUnits } from '@/bundles/MeasurementUnits/types';
import numeral from 'numeral';
import { formatAcresCutZero, formatRawInteger } from './filters';

export const conversionToSFFactors = {
  [SpaceMeasurementUnits.sf]: 1,
  [SpaceMeasurementUnits.sm]: 10.764,
  [SpaceMeasurementUnits.acres]: 43560,
};

export const conversionToFeetFactors = {
  [BuildingDimensionMeasurementUnits.inches]: 1,
  [BuildingDimensionMeasurementUnits.cm]: 2.54
}

const formattingBehaviors = {
  [SpaceMeasurementUnits.sf]: formatSf,
  [SpaceMeasurementUnits.sm]: formatSM,
  [SpaceMeasurementUnits.acres]: formatAcres
};

export const conversionBehaviors = {
  [SpaceMeasurementUnits.sf]: getConversionFunction(conversionToSFFactors[SpaceMeasurementUnits.sf]),
  [SpaceMeasurementUnits.sm]: getConversionFunction(conversionToSFFactors[SpaceMeasurementUnits.sm]),
  [SpaceMeasurementUnits.acres]: getConversionFunction(conversionToSFFactors[SpaceMeasurementUnits.acres])
};

const conversionToSFBehaviors = {
  [SpaceMeasurementUnits.sf]: getConversionToSFFunction(conversionToSFFactors[SpaceMeasurementUnits.sf]),
  [SpaceMeasurementUnits.sm]: getConversionToSFFunction(conversionToSFFactors[SpaceMeasurementUnits.sm]),
  [SpaceMeasurementUnits.acres]: getConversionToSFFunction(conversionToSFFactors[SpaceMeasurementUnits.acres])
};

const conversionToFeetBehaviors = {
  [BuildingDimensionMeasurementUnits.inches]: getConversionToFeetFunction(conversionToFeetFactors[BuildingDimensionMeasurementUnits.inches]),
  [BuildingDimensionMeasurementUnits.cm]: getConversionToFeetFunction(conversionToFeetFactors[BuildingDimensionMeasurementUnits.cm]),
};

function formatAcres (value) {
  return numeral(value).format('9,999.999'); // displaying other groupings/separators is possible, look at the docs
};

function formatSf (value) {
  return numeral(value).format('0,0'); // displaying other groupings/separators is possible, look at the docs
};

function formatSM (value) {
  return numeral(value).format('9,999.999');
}

function getConversionToSFFunction (conversionFactor) {
  // conversion to sf function
  return (value) => {
    return value * conversionFactor;
  }
}

function getConversionToFeetFunction (conversionFactor) {
  // conversion to feet function
  return (value) => {
    return value * conversionFactor;
  }
}

function getConversionFunction (conversionFactor) {
  // conversion to other unit of measure function
  return (value) => {
    const result = value / conversionFactor;
    return result === Math.ceil(result) ? result : +result.toFixed(5);
  }
}

export function formatSpaceToHumanReadable (value = 0, { from, to }, { cutZero } = {}) {
  const number = formatSpace(value, { from, to });

  if (!(to in formattingBehaviors)) {
    return number;
  }

  if (to === SpaceMeasurementUnits.acres && cutZero) {
    return `${formatAcresCutZero(number)} ${to.toUpperCase()}`;
  }

  return `${formattingBehaviors[to](number)} ${to.toUpperCase()}`;
}

export function formatSpace (value = 0, { from, to }) {
  let number = numeral(value).value();

  if (from === to) {
    return number;
  }

  if (from !== SpaceMeasurementUnits.sf && from in conversionToSFBehaviors) {
    number = conversionToSFBehaviors[from](number);
  }

  if (to in conversionBehaviors) {
    return conversionBehaviors[to](number);
  }

  return number;
}

export function formatBuildingDimension ({ value1 = 0, value2 = 0, total }, { from, to }) {
  let number = 0;
  if (total || total === 0) {
    number = numeral(total).value();
  } else {
    number = getTotalBuildingDimension({ value1, value2 }, from);
  }

  if (from === to) {
    return splitBuildingDimension(number, to);
  }

  if (from !== BuildingDimensionMeasurementUnits.inches && from in conversionToFeetBehaviors) {
    number = conversionToFeetBehaviors[from](number);
  }

  return splitBuildingDimension(number, to);
}

export function splitBuildingDimension (value, to) {
  let value1;
  let value2;
  let total;

  if (to === BuildingDimensionMeasurementUnits.cm) {
    value2 = value % 10;
    const rest = value - value2 > 0 ? value - value2 : 0
    value1 = rest / 10;
    total = value * 2.54;
  } else {
    value2 = value % 12;
    const rest = value - value2 > 0 ? value - value2 : 0
    value1 = rest / 12;
    total = value;
  }

  return {
    value1,
    value2,
    total
  };
}

export function getTotalBuildingDimension ({ value1, value2 }, from) {
  if (from === BuildingDimensionMeasurementUnits.inches) {
    return conversionToFeetBehaviors[from](value1) * 12 + conversionToFeetBehaviors[from](value2);
  }

  return conversionToFeetBehaviors[from](value1) * 10 + conversionToFeetBehaviors[from](value2);
}

export function formatBuildingDimensionToHumanReadable (total = 0, { from, to }) {
  const { value1, value2 } = formatBuildingDimension({ total }, { from, to });

  return `${isValuePresent(value1) && value1 !== 0 ? `${formatRawInteger(value1)}'` : ''}${isValuePresent(value2) && value2 !== 0 ? `${formatRawInteger(value2)}"` : ''}`;
}
