export const availableFilters = {
  status: 'select',
  type: 'select',
  recently_completed: 'boolean',
  completed_date_range: 'date_range',
  section: 'select',
  priority: 'range'
};

export const expandedFiltersName = {
  status: 'Status',
  type: 'Type',
  recently_completed: 'Recently Completed (last 7 days)',
  completed_date_range: 'Completed Date',
  section: 'Section',
  priority: 'Priority',
};

export const selectItems = {
  status: [
    { text: 'Open', value: 'Open' },
    { text: 'Closed', value: 'Closed' },
    { text: 'Pending', value: 'Pending' },
  ],
  type: [
    { text: 'Feature', value: 'feature' },
    { text: 'Bug', value: 'bug' },
  ],
  section: [
    { text: 'Spatial GIS', value: 'spatial-search' },
    { text: 'Companies', value: 'companies' },
    { text: 'Contacts', value: 'contacts' },
    { text: 'Occupiers', value: 'occupiers' },
    { text: 'Requirements', value: 'requirements' },
    { text: 'Properties', value: 'properties' },
    { text: 'Availabilities', value: 'availabilties' },
    { text: 'Comparables', value: 'comparables' },
    { text: 'Projects', value: 'projects' },
    { text: 'Saved Sets', value: 'savedsets' },
    { text: 'Pipelines', value: 'pipelines' },
    { text: 'Closed Deals', value: 'deals' }
  ],
  priority: [
    { text: '1', value: '1' },
    { text: '2', value: '2' },
    { text: '3', value: '3' },
    { text: '4', value: '4' },
    { text: '5', value: '5' },
    { text: '6', value: '6' },
    { text: '7', value: '7' },
    { text: '8', value: '8' },
    { text: '9', value: '9' },
    { text: '10', value: '10' },
  ],
  difficulty: [
    { text: '1', value: '1' },
    { text: '2', value: '2' },
    { text: '3', value: '3' },
    { text: '4', value: '4' },
    { text: '5', value: '5' },
    { text: '6', value: '6' },
    { text: '7', value: '7' },
    { text: '8', value: '8' },
    { text: '9', value: '9' },
    { text: '10', value: '10' },
  ],
};

export const getGlobalFilters = () => ({
  group: 'request',
  model: false,
  isActive: false,
  icon: 'mdi-account-multiple',
  title: 'Requests',

  children: [
    {
      icon: 'mdi-adjust',
      title: 'Request Type',
      items: [
        { text: 'Feature', value: 'feature' },
        { text: 'Bug', value: 'bug' },
      ],
      format: 'currency',
      name: 'request_type',
      type: 'one_select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Request Status',
      name: 'request_status',
      type: 'select',
      items: [
        { text: 'Open', value: 'Open' },
        { text: 'Closed', value: 'Closed' },
        { text: 'Reopen', value: 'reopen' },
        { text: 'Pending', value: 'Pending' },
        { text: 'Planning', value: 'planning' },
        { text: 'QA', value: 'QA' },
        { text: 'In Progress', value: 'in-progress' }
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Show recently completed requests (last 7 days)',
      items: [],
      format: 'currency',
      name: 'request_recently_completed',
      type: 'simple_switch',
    },
    {
      icon: 'mdi-adjust',
      title: 'Completed date',
      items: [],
      format: 'currency',
      name: 'request_completed_date_range',
      type: 'date_range',
    },
    {
      title: 'Request section',
      items: [
        { text: 'Spatial GIS', value: 'spatial-search' },
        { text: 'Companies', value: 'companies' },
        { text: 'Contacts', value: 'contacts' },
        { text: 'Occupiers', value: 'occupiers' },
        { text: 'Requirements', value: 'requirements' },
        { text: 'Properties', value: 'properties' },
        { text: 'Availabilities', value: 'availabilties' },
        { text: 'Comparables', value: 'comparables' },
        { text: 'Projects', value: 'projects' },
        { text: 'Saved Sets', value: 'savedsets' },
        { text: 'Pipelines', value: 'pipelines' },
        { text: 'Closed Deals', value: 'deals' }
      ],
      name: 'request_section',
      type: 'create_updated_select',
    },
    {
      title: 'Request Account',
      items: [
        { text: 'Gallelli Real Estate', value: 19 },
        { text: 'Lee & Associates - Atlanta', value: 13 },
        { text: 'Lee & Associates - DFW ', value: 3 },
        { text: 'Lee & Associates - DFW2 ', value: 11 },
        { text: 'Lee & Associates - Ft. Myers', value: 7 },
        { text: 'Lee & Associates - Houston', value: 1 },
        { text: 'Lee & Associates I Chicago', value: 20 },
        { text: 'Lee & Associates - Indianapolis', value: 16 },
        { text: 'Lee & Associates - Los Angeles Downtown', value: 6 },
        { text: 'Lee & Associates - Nashville', value: 14 },
        { text: 'Lee & Associates - Ontario', value: 15 },
        { text: 'Lee & Associates - Toronto', value: 2 },
        { text: 'Lee & Associates | WA-Pacific Northwest', value: 10 },
        { text: 'Radius Commercial Real Estate', value: 18 },
        { text: 'Slick Cactus', value: 12 },
        { text: 'Slick Cactus Demo', value: 5 },
        { text: 'Spatial Search', value: 24 }
      ],
      name: 'request_account',
      type: 'request_account',
    },
    {
      title: 'Priority',
      items: [],
      name: 'request_priority',
      type: 'range',
    },
    {
      title: 'Development Status',
      items: [
        { text: 'Assigned', value: 'assigned' },
        { text: 'Scheduled', value: 'scheduled' },
        { text: 'Unassigned', value: 'unassigned' },
        { text: 'Unscheduled', value: 'unscheduled' }
      ],
      name: 'request_assigned',
      type: 'one_select',
    },
    {
      title: 'Due Date',
      items: [
        { text: 'This Week', value: 'week' },
        { text: 'This Month', value: 'month' },
        { text: 'Past Due', value: 'past' },
      ],
      name: 'request_due_date',
      type: 'one_select',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'request_view',
      type: 'create_updated_select',
    },
    {
      title: 'Filtered Requests',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
