




















































































































































































































































































































































































































































































import Vue from 'vue';
import { getDateDiffAbs } from '@/utils/filters';
import { getAccountRouteHandler } from '@/bundles/App/helpers/accountRoute';
import { getNBRPropertyTypeClass } from '@/bundles/Property/helpers/colors/getNBRPropertyTypeClass';
import { roundToDecimal } from '@/bundles/App/helpers/mathHelpers';
import { getAvailabilityExpensesLabel } from '@/bundles/Property/helpers/expenses/getAvailabilityExpensesLabel';

import Photo from '@/components/common-components/photo/Photo.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';
import LandSizeLabel from '@/bundles/MeasurementUnits/components/LandSizeLabel.vue';
import POLIcon from '@/bundles/Comparables/components/POLIcon.vue';
import AvailabilityVerificationLabel from '@/bundles/Availabilities/components/AvailabilityVerificationLabel.vue';
import AvailabilityChipsWrapper from '@/bundles/Availabilities/components/chips/AvailabilityChipsWrapper.vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';
import Label from '@/bundles/Common/components/Label.vue';
import BuildingDimensionLabels from '@/bundles/MeasurementUnits/components/BuildingDimensionLabels.vue';

export default Vue.extend({
  name: 'ClientContent',

  components: {
    CommonChip,
    AvailabilityChipsWrapper,
    Photo,
    BuildingSizeLabel,
    LandSizeLabel,
    POLIcon,
    AvailabilityVerificationLabel,
    BuildingDimensionLabels,
    Label
  },

  props: {
    props: {
      type: Object,
      required: true,
    },
    selected: Boolean,
    displayBrokers: Boolean,
    displayOwners: Boolean,
    trackTour: Boolean,
    allowSelect: {
      type: Boolean,
      default: true
    },
    activeAccountId: {
      type: Number,
      required: true
    },
    allowLink: {
      type: Boolean,
      default: true
    },
    allowPhoto: {
      type: Boolean,
      default: true
    },
    allowCustomSort: Boolean,
    isCustomOrderLoading: Boolean,
  },

  methods: {
    getNBRPropertyTypeClass,
    getAvailabilityExpensesLabel,
    isLand (props) {
      return props.type?.toLowerCase() === 'land';
    },
    showVacancyStatus (props) {
      return props.property.construction_status?.toLowerCase() === 'existing';
    },
    getComments (props) {
      const listingComments = props.listing_comments || '';
      const propertyComments = props.property_comments || '';

      if (listingComments && propertyComments) {
        return listingComments + ' -- ' + propertyComments;
      }

      if (listingComments && !propertyComments) {
        return listingComments;
      }

      return propertyComments;
    },
    monthsOnMarket (date) {
      const days = getDateDiffAbs(date, ['days']).days;

      return days < 30 ? roundToDecimal(days / 30, 1) : Math.round(days / 30);
    },
    getAccountRoute ({ name, params, activeAccountId }: {
      name: string;
      params: { id: number };
      activeAccountId: number;
    }) {
      return getAccountRouteHandler({
        name,
        params,
        activeAccountId
      });
    },
    getCompanyName (availabilityBrokers) {
      return availabilityBrokers?.[0]?.company?.name || '';
    },
  }
});
