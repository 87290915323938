import * as constants from './settings-types'
import * as actionTypes from './settings-actions-types';
import instance from '@/services/instance'
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { userLayoutService } from '@/bundles/Settings/factories/userLayoutServiceFactory';
import { memberOldService } from '@/bundles/Members/factory/memberOldServiceFactory';

const errorMapper = new ErrorMapper();

export default {
  async [constants.SET_ALL_PERMISSIONS] ({ commit, rootState }, { id, permissions }) {
    const { data: response } = await instance.patch(`/member-settings/${ id }/permissions`, permissions, {
      headers: {
        Authorization: 'Bearer ' + rootState.idToken,
      },
    });
    commit(constants.SET_ALL_PERMISSIONS, response.permissions);
  },
  async [constants.UPDATE_PAGE_LAYOUT] ({ commit }, { widgets, page }) {
    try {
      const response = await userLayoutService.updateLayoutSettings({ widgets, page });

      commit(constants.SET_LAYOUT_SETTINGS, response);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  async [constants.INITIALIZE_PAGE_LAYOUT] ({ commit }, { widgets, page }) {
    try {
      const response = await userLayoutService.initializeLayoutSettings({ widgets, page });

      commit(constants.SET_LAYOUT_SETTINGS, response);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  async [constants.GET_LAYOUT_SETTINGS] ({ commit }, { page }) {
    try {
      const response = await userLayoutService.getLayoutSettings(page);

      commit(constants.SET_LAYOUT_SETTINGS, response);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  async [actionTypes.UPDATE_DEVICE_TOKENS] ({ commit, rootState }, { id, tokens }) {
    const { data: response } = await instance.patch(`/member-settings/${ id }/device-token`, { tokens }, {
      headers: {
        Authorization: 'Bearer ' + rootState.idToken,
      },
    });
    commit(constants.SET_DEVICE_TOKENS, response.device_tokens);
  },
  [constants.SEND_FEEDBACK] ({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      instance
        .post('/request', payload, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  [constants.GET_ALL_PERMISSIONS] ({ rootState, commit }, id) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/member/${id}`, {
          headers: {
            Authorization: 'Bearer ' + rootState.idToken,
          },
        })
        .then((response) => {
          let { permissions } = response.data[0]
          let payload = {}
          permissions.forEach((item) => {
            payload = Object.assign(payload, item)
          })
          commit(constants.SET_ALL_PERMISSIONS, payload)
          resolve(payload)
        })
        .catch((err) => {
          reject(new Error(err))
        })
    })
  },
  async [actionTypes.GET_USER_SETTINGS] ({ rootState, commit }, id) {
    const { data: response } = await instance.get(`/member-settings/${ id }`, {
      headers: {
        Authorization: 'Bearer ' + rootState.idToken,
      },
    });
    commit(constants.SET_SETTINGS_KEY, response._key);
    commit(constants.SET_ALL_PERMISSIONS, response.permissions);
    commit(constants.SET_DEVICE_TOKENS, response.device_tokens);
    commit(constants.SET_USER_NOTIFICATION_SETTINGS, response.notifications);
    commit(constants.SET_USER_OPEX_DISPLAY, response.opex_display);
  },
  async [actionTypes.UPDATE_USER_NOTIFICATIONS_SETTINGS] ({ commit }, { id, data }) {
    const response = await memberOldService.updateMemberNotificationsSettings(id, data)
    commit(constants.SET_USER_NOTIFICATION_SETTINGS, response);
  },
  async [actionTypes.UPDATE_USER_OPEX_DISPLAY] ({ commit }, { id, data }) {
    const response = await memberOldService.updateMemberOpexDisplaySettings(id, data)
    commit(constants.SET_USER_OPEX_DISPLAY, response);
  },
}
