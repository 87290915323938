import { ContactRolesEnum } from '@/bundles/Contact/enums/contactRoles';
import { RequirementStatus } from '@/bundles/Requirements/types/statusTypes';

export const availableFilters = {
  industry_type: 'autocomplete',
  transaction_size: 'range',
  transaction_type: 'autocomplete',
  office_size: 'range',
};

export const expandedFiltersName = {
  industry_type: 'Use Type',
  transaction_type: 'Transaction Type',
  transaction_size: 'Transaction Size',
  office_size: 'Office Size',
};

export const autocompleteItems = {
  industry_type: [
    { text: 'All', value: 'All' },
    { text: 'Wholesaler', value: 'Wholesaler' },
    { text: 'Retailer', value: 'Retailer' },
    { text: 'Manufacturer', value: 'Manufacturer' },
    { text: '3PL/Transportation', value: '3PL/Transportation' },
    { text: 'Consumer Products', value: 'Consumer Products' },
    { text: 'E-Commerce', value: 'E-Commerce' },
    { text: 'Assembly/Service', value: 'Assembly/Service' },
    { text: 'Communications', value: 'Communications' },
    { text: 'Agri/Mining/Utilities', value: 'Agri/Mining/Utilities' },
    { text: 'Business Services', value: 'Business Services' },
  ],
  transaction_type: [
    { text: 'Purchase', value: 'purchase' },
    { text: 'Lease', value: 'lease' },
  ],
};

export const getGlobalFilters = () => ({
  group: 'requirements',
  model: false,
  isActive: false,
  icon: 'mdi-check-all',
  title: 'Requirements',
  children: [
    {
      icon: 'mdi-adjust',
      title: 'Use Type',
      items: [
        { text: '3PL/Transportation', value: '3PL/Transportation' },
        { text: 'Agri/Mining/Utilities', value: 'Agri/Mining/Utilities' },
        { text: 'Assembly/Service', value: 'Assembly/Service' },
        { text: 'Business Services', value: 'Business Services' },
        { text: 'Communications', value: 'Communications' },
        { text: 'Consumer Products', value: 'Consumer Products' },
        { text: 'E-Commerce', value: 'E-Commerce' },
        { text: 'Manufacturer', value: 'Manufacturer' },
        { text: 'Retailer', value: 'Retailer' },
        { text: 'Wholesaler', value: 'Wholesaler' },
      ],
      name: 'requirement_industry_type',
      type: 'select'
    },
    {
      icon: 'mdi-adjust',
      title: 'Transaction Type',
      items: [
        { text: 'Purchase', value: 'purchase' },
        { text: 'Lease', value: 'lease' },
      ],
      name: 'requirement_transaction_type',
      type: 'one_select'
    },
    {
      icon: 'mdi-adjust',
      title: 'Requirement Status',
      items: [
        { text: 'Active', value: RequirementStatus.active },
        { text: 'On Hold', value: RequirementStatus.on_hold },
        { text: 'Purchase Completed', value: RequirementStatus.purchase_completed },
        { text: 'Lease Completed', value: RequirementStatus.lease_completed },
        { text: 'Dead', value: RequirementStatus.dead },
      ],
      name: 'requirement_status',
      type: 'select'
    },
    {
      icon: 'mdi-adjust',
      title: 'Transaction Size',
      items: ['', ''],
      name: 'requirement_transaction_size',
      type: 'range',
      options: {
        prefix: '$'
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Building Size',
      items: ['', ''],
      name: 'requirement_building_size',
      type: 'range',
      options: {
        suffix: '/ SF'
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Office Size',
      items: ['', ''],
      name: 'requirement_office_size',
      type: 'range',
      options: {
        suffix: '/ SF'
      }
    },
    {
      title: 'Permissioned for',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      hideInactive: true,
      name: 'requirement_permission_view',
      type: 'create_updated_select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Contact Filters'
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Name',
      name: 'requirement_contact_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Requirement Role',
      items: [
        // TODO sychdo: need to take roles from data lists
        { text: 'Architect', value: ContactRolesEnum.architect },
        { text: 'Civil Engineer', value: ContactRolesEnum.civil_engineer },
        { text: 'Developer', value: ContactRolesEnum.developer },
        { text: 'Listing Agent', value: ContactRolesEnum.listing_agent },
        { text: 'Listing Agent - Sublease', value: ContactRolesEnum['listing_agent-_-sublease'] },
        { text: 'Owner', value: ContactRolesEnum.owner },
        { text: 'Property Manager', value: ContactRolesEnum.property_manager },
        { text: 'Structural Engineer', value: ContactRolesEnum.structural_engineer },
        { text: 'True Owner', value: ContactRolesEnum.true_owner },
        { text: 'Previous Owner', value: ContactRolesEnum.previous_owner },
        { text: 'Local Manager', value: ContactRolesEnum.local_manager },
        { text: 'Ultimate Decision Maker', value: ContactRolesEnum.ultimate_decision_maker },
        { text: 'Procuring Broker', value: ContactRolesEnum.procuring_broker },

      ],
      name: 'requirement_contact_role',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'requirement_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'requirement_view',
      type: 'create_updated_select',
    },
    {
      title: 'Filtered Requirements',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ]
});
