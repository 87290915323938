


















































































import Vue, { PropType } from 'vue';
import { ITask } from '@/bundles/Tasks/interfaces/ITask';
import { getTasksTableHeaders } from '@/bundles/Tasks/helpers/tableHeaders';
import { IHeader } from '@/bundles/BaseTable/interfaces';
import { mapMutations, mapState } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { getDefaultTaskPagination, ITasksTablePagination } from '@/bundles/Tasks/helpers/defaultPagination';
import { SET_TASK_TABLE_PER_PAGE } from '@/store/modules/task/types';
import { Nullable } from '@/utils/types';
import { cloneDeep } from 'lodash';

import TaskTableContentItem from '@/bundles/Tasks/components/table/TaskTableContentItem.vue';
import EditTaskForm from '@/bundles/Tasks/components/EditTaskForm.vue';

interface ITasksTable {
  pagination: ITasksTablePagination;
  editTaskDialog: boolean;
  taskToEdit: Nullable<ITask>;
}

export default Vue.extend({
  name: 'TasksTable',

  components: { EditTaskForm, TaskTableContentItem },

  props: {
    tasks: {
      type: Array as PropType<ITask[]>,
      default: () => [],
    },
    totalRows: {
      type: Number,
      required: true,
    },
    collectionType: {
      type: String,
      default: null
    },
    selected: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    associatedRecords: {
      type: Array,
      default: () => []
    },
    isTableLoading: Boolean,
  },

  data: (): ITasksTable => ({
    pagination: getDefaultTaskPagination(),
    editTaskDialog: false,
    taskToEdit: null,
  }),

  computed: {
    ...mapState('Task', {
      tasksItemsPerPage: (state: any): number => state.tableOptions.itemsPerPage,
    }),
    headers (): IHeader[] {
      return getTasksTableHeaders();
    },
    pagesLength (): number {
      if (!this.totalRows) {
        return 1;
      }

      return Math.ceil(this.totalRows / this.itemsPerPage);
    },
    itemsPerPage: {
      get (): number {
        return this.tasksItemsPerPage;
      },
      set (value: number) {
        this.setTaskTablePerPage(value);
      },
    }
  },

  watch: {
    'pagination.page' () {
      this.paginationChangeHandler();
    },
    'pagination.sortBy' () {
      this.paginationChangeHandler();
    },
    'pagination.descending' () {
      this.paginationChangeHandler();
    },
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    ...mapMutations('Task', {
      setTaskTablePerPage: SET_TASK_TABLE_PER_PAGE,
    }),
    perPageChangeHandler () {
      const isFirstPage = this.pagination.page === 1;

      this.pagination.page = 1;

      if (isFirstPage) {
        this.paginationChangeHandler();
      }
    },
    paginationChangeHandler () {
      this.getTasks(true);
    },
    getTasks (isPaginationChanged = false) {
      this.$emit('fetch:tasks', { isPaginationChanged, pagination: this.pagination });
    },
    toggleHandler (value: boolean) {
      const selectedArr = value ? this.tasks.map((item) => item._key) : [];
      this.selectTasksHandler(selectedArr);
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    selectItemHandler (taskId) {
      const selectedArr = this.selected.includes(taskId)
          ? this.selected.filter((key) => key !== taskId)
          : [...this.selected, taskId];
      this.selectTasksHandler(selectedArr);
    },
    selectTasksHandler (selected: string[]) {
      this.$emit('select:tasks', selected);
    },
    editTaskHandler (task: ITask) {
      this.taskToEdit = cloneDeep(task);
      this.editTaskDialog = true;
    },
  }
});
