import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/occupier';

export default {
  list: async function ({ params = [], token, onDownloadEventHandler = null }) {
    try {
      let paramsString = generateParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      return await instance.get(`${resource}/list${paramsString}`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getReport: async function ({ params = [], token }) {
    try {
      let paramsString = generateParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      return instance.get(`${resource}/report${paramsString}`, config)
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archiveOne: async function ({ token, id }) {
    try {
      return await instance.patch(`${resource}/${id}/archive`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRecord: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/record`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  createSchedule: async function ({ token, id, payload }) {
    try {
      return await instance.put(`${resource}/${id}/saveSchedule`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  deleteSchedule: async function ({ token, id, payload }) {
    try {
      return await instance.put(`${resource}/${id}/removeRentSchedule`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addExpense: async function ({ token, id, payload }) {
    try {
      return await instance.put(`${resource}/${id}/addExpense`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  deleteExpense: async function ({ token, id, payload }) {
    try {
      return await instance.put(`${resource}/deleteExpense/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getSchedule: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/rentSchedules`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOneSchedule: async function ({ token, id, rentScheduleKey }) {
    try {
      return await instance.get(`${resource}/${id}/rentSchedule/${rentScheduleKey}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getContacts: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/contacts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addContact: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/addContact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLocation: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/location`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addLeaseDate: async function ({ token, id, payload }) {
    try {
      return await instance.post(`${resource}/${id}/addLeaseDate`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getLeaseDateList: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/leaseDateList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updatePeriod: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/updatePeriod/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeProperty: async function ({ id, token, payload }) {
    try {
      return await instance.delete(`${resource}/${id}/property/${payload.propertyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateScheduleCommissions: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/scheduleCommissions`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLeaseDate: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/editLeaseDate`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
