export const availableFilters = {
  show_my_teams: 'boolean',
  record_type: 'select',
};

export const getGlobalFilters = () => ({
  group: 'pressrelease',
  model: false,
  isActive: false,
  icon: 'mdi-account-multiple',
  title: 'Press Releases',

  children: [
    {
      icon: 'mdi-adjust',
      title: 'Status',
      items: [
        { text: 'New', value: 'feature' },
        { text: 'Pending Approval', value: 'pendingApproval' },
        { text: 'sent', value: 'sent' },
      ],
      format: 'currency',
      name: 'pressrelease_status',
      type: 'one_select',
    },
    {
      title: 'Filtered Press Releases',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
