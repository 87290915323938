import * as constants from './filters-types';
import { ServiceFactory } from '@/services/ServiceFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { teamsService } from '@/bundles/Teams/factory/teamsFactory';
import { memberService } from '@/bundles/Members/factory/memberServiceFactory';

const errorMapper = new ErrorMapper();

const MarketsService = ServiceFactory.get('markets');

export default {
  async [constants.GET_ALL_DATA] ({ commit, dispatch, store }, { token }: { token: string }) {
    try {
      const getRequestsNames = [
        constants.GET_MARKETS,
        constants.GET_BUSINESS_PARKS,
        constants.GET_SUB_MARKETS,
        constants.GET_MEMBER_TEAMS,
        constants.GET_MEMBERS,
      ];

      await Promise.all(getRequestsNames.map(name => dispatch(name, { token })));
    } catch (e) {
      throw e;
    } finally {
      commit(constants.SET_IS_FILTERS_DATA_LOADED, true);
    }
  },
  async [constants.GET_MARKETS] ({ commit }, { token }: { token: string }) {
    try {
      const { data } = await MarketsService.getMarkets(token);

      commit(constants.SET_FILTERS_DATA, { key: 'markets', data });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  async [constants.GET_BUSINESS_PARKS] ({ commit }, { token }: { token: string }) {
    try {
      const { data } = await MarketsService.getBusinessParks(token);

      commit(constants.SET_FILTERS_DATA, { key: 'businessParks', data });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  async [constants.GET_SUB_MARKETS] ({ commit }, { token }: { token: string }) {
    try {
      const { data } = await MarketsService.getSubMarkets(token);

      commit(constants.SET_FILTERS_DATA, { key: 'subMarkets', data });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  async [constants.GET_MEMBER_TEAMS] ({ commit }) {
    try {
      const data = await teamsService.myTeams();

      commit(constants.SET_FILTERS_DATA, { key: 'teams', data });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  async [constants.GET_MEMBERS] ({ commit }) {
    try {
      commit(constants.SET_IS_LOADING_MEMBERS_LIST, true);

      const { data } = await memberService.list([{ name: 'take', value: 0 }]);

      commit(constants.SET_FILTERS_DATA, { key: 'memberList', data });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    } finally {
      commit(constants.SET_IS_LOADING_MEMBERS_LIST, false);
    }
  },
}
