import { ContactRolesEnum } from '@/bundles/Contact/enums/contactRoles';
import { getUniqueSortedUseTypesByType } from '@/bundles/Property/helpers/getUseTypesByType';
import i18n from '@/bundles/Localization';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';
import { getPropertyCenterTypeTextValues } from '@/bundles/Property/enums/PropertyCenterTypeEnum';
import { getSaleConditionsTextValues } from '@/bundles/Comparables/enums/SaleConditionsEnum';
import { sprinklerTypes } from '@/bundles/Properties/helpers';

export const availableFilters = {
  comparable_city: 'string',
  saleOrLease: 'autocomplete',
  property_type: 'select',
  transactionDate: 'range',
  size: 'range',
  propertySize: 'range',
  salePrice: 'currencyRange',
  leaseRate: 'currencyRange',
  commencementDate: 'dateRange',
  expirationDate: 'dateRange',
  tiLandlord: 'currencyRange',
  tiTenant: 'currencyRange',
  buyerCompanyContact: 'string',
  sellerCompanyContact: 'string',
  tenantCompanyContact: 'string',
  landlordCompanyContact: 'string',
};

export const expandedFiltersName = {
  keyword: 'Keyword ',
  comparable_city: 'City',
  saleOrLease: 'Transaction Type',
  property_type: 'Property Type',
  size: 'Comparable Total SF',
  propertySize: 'Property Total SF',
  salePrice: 'Sale Price',
  leaseRate: 'Lease Rate',
  commencementDate: 'Commencement Date',
  expirationDate: 'Expiration Date',
  transactionDate: 'Transaction Date',
  tiLandlord: 'TI Landlord',
  tiTenant: 'TI Tenant',
  buyerCompanyContact: 'Buyer',
  sellerCompanyContact: 'Seller',
  tenantCompanyContact: 'Tenant',
  landlordCompanyContact: 'Landlord',
};

export const autocompleteItems = {
  saleOrLease: [
    { text: 'For Sale', value: 'sale' },
    { text: 'For Lease', value: 'lease' },
  ],
  contact_roles: [
    { text: 'Tenant Contact', value: 'tenant' },
    { text: 'Landlord Contact', value: 'landloard' },
    { text: 'Tenant Agent', value: 'tenant_agent' },
    { text: 'Landlord Agent', value: 'landlord_agent' },
    { text: 'Buyer Agent', value: 'buyer_agent' },
    { text: 'Seller Agent', value: 'seller_agent' },
    { text: 'Other', value: 'other' },
    { text: 'Source', value: 'source' }
  ],
  sprinklers: sprinklerTypes,
};

export const selectItems = {
  property_type: [],
};

export const getGlobalFilters = () => ({
  group: 'comparables',
  model: false,
  isActive: false,
  icon: 'mdi-compare',
  title: 'Comparables',
  children: [
    {
      icon: 'mdi-adjust',
      title: 'In House',
      name: 'comparable_in_house',
      type: 'inhouse'
    },
    {
      icon: 'mdi-adjust',
      title: 'Confidential',
      name: 'comparable_confidential',
      type: 'confidential',
      items: []
    },
    {
      type: 'custom_transaction_filter',
      children: [
        {
          title: 'Transaction Type',
          name: 'comparable_transaction_type',
        },
        {
          title: 'Investment Sale',
          name: 'comparable_investment'
        },
        {
          title: 'SubLease',
          name: 'comparable_sublease'
        }
      ]
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.sale_conditions),
      items: getSaleConditionsTextValues(),
      name: 'comparable_sale_conditions',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Type',
      items: [],
      name: 'property_type',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Comp Space Size',
          name: 'comparable_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'comparable_land_size',
          type: 'range'
        },
        {
          name: 'comparable_land_size_unit',
          type: 'helper'
        }
      ],
      meta: {
        title: 'Comp Space Size'
      }
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.number_of_units),
      items: ['', ''],
      name: 'comparable_building_units',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Land Size POL',
      name: 'comparable_land_pol',
      type: 'pol',
    },
    {
      type: 'custom_price_filter',
      children: [
        {
          title: 'Lease Rate',
          name: 'comparable_lease_rate',
          type: 'range'
        },
        {
          title: 'Sale Price',
          name: 'comparable_sales_price',
          type: 'range'
        },
        {
          name: 'comparable_sales_price_unit',
          type: 'helper'
        },
        {
          title: 'Sale Price Per',
          name: 'comparable_sales_price_sf',
          type: 'range'
        },
        {
          name: 'comparable_sales_price_sf_unit',
          type: 'helper'
        },
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Transaction Date',
      name: 'comparable_transactionDate',
      type: 'date_range'
    },
    {
      icon: 'mdi-adjust',
      title: 'Commencement Date',
      name: 'comparable_commencementDate',
      type: 'date_range'
    },
    {
      icon: 'mdi-adjust',
      title: 'Expiration Date',
      name: 'comparable_expirationDate',
      type: 'date_range'
    },
    {
      icon: 'mdi-adjust',
      title: 'TI Landlord (per sf)',
      name: 'comparable_tiLandlord',
      type: 'range'
    },
    {
      icon: 'mdi-adjust',
      title: 'TI Tenant (per sf)',
      name: 'comparable_tiTenant',
      type: 'range'
    },
    {
      type: 'divider'
    },
    {
      icon: 'mdi-adjust',
      title: 'Source Contact',
      items: ['', ''],
      name: 'comparable_source_contact',
      type: 'broker',
    },
    {
      type: 'divider'
    },
    {
      icon: 'mdi-adjust',
      title: 'Broker',
      items: ['', ''],
      name: 'comparable_broker',
      type: 'broker',
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'comparable_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'comparable_view',
      type: 'create_updated_select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Property Filters'
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Type',
      items: [],
      name: 'property_type',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Primary Use',
      items: [],
      name: 'property_primary_use',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Use Type(s)',
      name: 'comparable_use_type',
      type: 'use_type_filter',
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.retail_center_type),
      items: getPropertyCenterTypeTextValues(),
      name: 'property_center_type',
      type: 'select',
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Property Size',
          name: 'property_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'property_land_size',
          type: 'range'
        },
        {
          name: 'property_land_size_unit',
          type: 'helper'
        }
      ],
      meta: {
        prefix: 'property',
        title: 'Property Size'
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Year Built',
      name: 'property_year_built',
      type: 'date_range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Sprinkler  Type',
      items: sprinklerTypes,
      name: 'property_sprinklers',
      type: 'select',
    },
    {
      type: 'clear_height',
      title: 'Clear Height',
      children: [
        {
          title: 'Min Clear Height',
          type: 'building_dimension_range',
          name: 'property_min_clear_height',
        },
        {
          title: 'Max Clear Height',
          type: 'building_dimension_range',
          name: 'property_max_clear_height'
        },
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Property # Dock High Doors',
      items: ['', ''],
      name: 'property_dock_door_count',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property # Drive-In Doors',
      name: 'property_ground_level_total',
      type: 'range'
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Drive-In Doors Width',
      name: 'property_ground_level_width',
      type: 'building_dimension_range'
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Building Class',
      items: ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-'],
      name: 'property_building_class',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Construction Status',
      items: [
        { text: 'Proposed', value: 'proposed' },
        { text: 'Under Construction', value: 'under construction' },
        { text: 'Existing', value: 'existing' },
      ],
      name: 'property_construction_status',
      type: 'select',
    },
    {
      type: 'title',
      title: 'Market & Location'
    },
    {
      icon: 'mdi-adjust',
      title: 'City',
      name: 'property_location_city',
      type: 'city_combobox',
      class: 'mt-0 pa-0'
    },
    {
      icon: 'mdi-adjust',
      title: 'State',
      name: 'property_location_state',
      type: 'states_combobox',
    },
    {
      icon: 'mdi-adjust',
      title: 'Zip / Postal Code',
      name: 'comparable_zip_code',
      type: 'postal_code_combobox',
    },
    {
      icon: null,
      title: null,
      items: [],
      name: 'property_market',
      type: 'dynamic_market',
      children: [
        {
          icon: 'mdi-adjust',
          title: `Property ${i18n.t('apn_number')}`,
          items: ['', ''],
          name: 'property_apn_number',
          type: 'textfield',
        },
      ]
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Company & Contact'
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Name',
      name: 'company_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Name',
      name: 'contact_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Company Role',
      items: [
        { text: 'Agent', value: ContactRolesEnum.agent },
        { text: 'Contractor', value: ContactRolesEnum.contractor },
        { text: 'Employee', value: ContactRolesEnum.employee },
      ],
      name: 'contact_company_role',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Comparable Role',
      items: autocompleteItems.contact_roles,
      name: 'comparable_contact_role',
      type: 'select',
    },
    {
      title: 'Filtered Comparables',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ]
});
