










































































import mixins from 'vue-typed-mixins';
import { PaginationMixin } from '@/bundles/BaseTable/mixins/PaginationMixin';
import { mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { criticalDateService } from '@/bundles/CriticalDates/factory/criticalDateServiceFactory';
import CriticalDateListResponse from '@/bundles/CriticalDates/models/CriticalDateListResponse';
import { CriticalDateType } from '@/bundles/CriticalDates/types';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { criticalDatesHeadersData } from '@/bundles/CriticalDates/helpers';
import { IHeader } from '@/bundles/BaseTable/interfaces';

import DashboardCard from '@/bundles/Dashboard/components/DashboardCard.vue';
import BaseTableHeaderMulti from '@/bundles/BaseTable/components/BaseTableHeaderMulti.vue';

interface ICriticalDateWidgetInstance {
  loading: boolean;
  items: CriticalDateListResponse[];
  totalRows: number;
  showOnlyFutureDates: boolean;
  tableOptionsType: 'criticalDatesDashboardWidget';
}

export default mixins(PaginationMixin).extend({
  name: 'CriticalDatesDashboardWidget',

  components: {
    DashboardCard,
    BaseTableHeaderMulti
  },

  props: {
    expanded: Boolean,
  },

  data: (): ICriticalDateWidgetInstance => ({
    loading: false,
    items: [],
    totalRows: 0,
    showOnlyFutureDates: false,
    tableOptionsType: 'criticalDatesDashboardWidget',
  }),

  computed: {
    widgetType (): UserWidgetsEnum.critical_dates {
      return UserWidgetsEnum.critical_dates;
    },
    headers (): IHeader[] {
      return criticalDatesHeadersData
    },
    isSortDescending () {
      return !!this.tableOptions.sortDesc?.[0];
    }
  },

  created () {
    this.tableOptions.itemsPerPage = 10;
  },

  watch: {
    tableOptions: {
      handler: function () {
        this.fetchWrapper();
      },
      deep: true,
      immediate: true,
    },
    showOnlyFutureDates: function () {
      if (this.tableOptions.page === 1) {
        this.fetchWrapper();
        return false;
      }
      this.tableOptions.page = 1;
    }
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    fetchWrapper: async function () {
      this.loading = true;
      try {
        const params = {
          take: this.tableOptions.itemsPerPage,
          skip: this.pageOffset,
          sort: this.tableOptions.sortBy?.[0],
          descending: this.tableOptions.sortDesc?.[0],
          show_only_future_dates: this.showOnlyFutureDates,
        };
        const { data, total } = await criticalDateService.list(params);
        this.items = data;
        this.totalRows = total;
        const wrapper = document.querySelector('.critical-date-container');
        if (wrapper) {
          wrapper.scrollTop = 0;
        }
      } catch (error: any) {
        this.addNotification({ ...error });
      } finally {
        this.loading = false;
      }
    },
    mapTypeToTitle: function (type: CriticalDateType): string {
      switch (true) {
        case type === CriticalDateType.effective_date_start:
          return 'Option Effective Date Start';
        case type === CriticalDateType.effective_date_end:
          return 'Option Effective Date End';
        case type === CriticalDateType.no_later_than_date:
          return 'No Later Than Notification';
        default:
          return 'No Earlier Than Notification';
      }
    },
    isActiveSort: function (item: IHeader): boolean {
      const sortBy = this.tableOptions?.sortBy || [];
      const valueText = item.value;

      return sortBy[0] === valueText;
    },
    sortChangeHandler: function (payload: { sortBy: string[]; sortDesc: boolean[] }) {
      this.setFilterSort('criticalDatesDashboardWidget', payload);
    },
  },
});
