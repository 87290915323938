
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue';
import { formatBuildingDimensionLabel } from '@/bundles/MeasurementUnits/helpers';
import store from '@/store/store';
import { Nullable } from '@/utils/types';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';

export default Vue.extend({
  name: 'BuildingDimensionLabel',

  functional: true,

  props: {
    value: {
      type: [String, Number] as PropType<Nullable<string | number>>,
      default: 0
    }
  },

  render (h: CreateElement, ctx: RenderContext): VNode {
    const value = ctx.props.value;

    if (!isValuePresent(value) || value === 0) {
      return h('span', { class: 'na' }, 'N/A');
    }

    const defaultLandSizeUnit = store.state.MeasurementUnits.buildingDimension;
    const selectedLandSizeUnit = store.state.accountSettings?.properties?.building_dimension_type?.value;

    const label = formatBuildingDimensionLabel(value, {
      from: defaultLandSizeUnit,
      to: selectedLandSizeUnit
    });

    if (!label) {
      return h('span', { class: 'na' }, 'N/A');
    }

    return h('span', label);
  }
});
