import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import TeamToMapper from '@/bundles/Teams/mappers/TeamToMapper';
import { PaginationResponse } from '@/types/PagintaionResponse';
import Team from '@/bundles/Teams/models/Team';
import { generateParams } from '@/utils/helpers';
import CreateTeamDto from '@/bundles/Teams/dto/create-team';
import UpdateTeamDto from '@/bundles/Teams/dto/update-team';

export default class TeamsService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;
  private readonly teamMapper: TeamToMapper;

  constructor ({
    transport,
    errorMapper,
    teamMapper,
  }: { transport: HttpTransport; errorMapper: ErrorMapper; teamMapper: TeamToMapper }) {
    this.teamMapper = teamMapper;
    this.transport = transport;
    this.errorMapper = errorMapper;
  }

  async list (params: any[]): Promise<PaginationResponse<Team>> {
    try {
      const paramsString = generateParams(params);
      const { data, total } = await this.transport.get(`/team${ paramsString }`);
      return {
        data,
        total,
      };
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async myTeams (): Promise<Team[]> {
    try {
      const response = await this.transport.get('/team/my-teams');
      return response.map((item) => this.teamMapper.mapToModel(item));
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async create (payload: CreateTeamDto): Promise<Team> {
    try {
      const response = await this.transport.post('/team', payload);
      return this.teamMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async update (id: number, payload: UpdateTeamDto): Promise<Team> {
    try {
      const response = await this.transport.put(`/team/${ id }`, payload);
      return this.teamMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async archive ({ ids = [] }: { ids: number[] }): Promise<void> {
    try {
      return this.transport.patch(`/team/archive`, { ids });
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
