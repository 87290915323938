













































































































import Vue from 'vue';
import { mapState } from 'vuex';
import { UserWidgetsEnum, UserWidgetsLabels } from '@/bundles/Settings/enums/userLayoutEnum';
import { Nullable, ValueOf } from '@/utils/types';
import { ServiceFactory } from '@/services/ServiceFactory';
import { ISortingData } from '@/bundles/BaseTable/interfaces';
import { getInvoiceAging, getInvoiceStatusProps } from '@/bundles/Deal/helpers';
import { ExportRecordType, ExportType } from '@/bundles/Common/types';

import DashboardCard from '@/bundles/Dashboard/components/DashboardCard.vue';
import OpenInvoicesTable from '@/bundles/Invoice/components/OpenInvoicesTable.vue';
import CommonDatePicker from '@/components/common-components/pickers/CommonDatePicker.vue';

interface IDashboardOpenInvoicesCardData {
  items: any[];
  loading: boolean;
  filterLoading: boolean;
  widgetType?: UserWidgetsEnum;
  pagination: ISortingData;
  exportLoading: boolean;
  date_start: Nullable<string>;
  date_end: Nullable<string>;
  title?: ValueOf<keyof typeof UserWidgetsLabels>;
}

const InvoiceService = ServiceFactory.get('invoice');
const SavedSetService = ServiceFactory.get('savedSets');

export default Vue.extend({
  name: 'DashboardOpenInvoicesCard',

  components: {
    DashboardCard,
    OpenInvoicesTable,
    CommonDatePicker
  },

  props: {
    expanded: Boolean,
  },

  data: (): IDashboardOpenInvoicesCardData => ({
    items: [],
    loading: true,
    filterLoading: false,
    pagination: {
      sortBy: ['daysDiff'],
      sortDesc: [true],
    },
    exportLoading: false,
    date_start: null,
    date_end: null,
  }),

  computed: {
    ...mapState({
      token: (state: any) => state.idToken,
    }),
    filterLabel (): string {
      const formatDate = (this.$options.filters as any).formatDate;

      if (this.date_start && this.date_end) {
        return `${formatDate(this.date_start)} - ${formatDate(this.date_end)}`;
      }

      if (this.date_start) {
        return `From ${formatDate(this.date_start)}`;
      }

      if (this.date_end) {
        return `To ${formatDate(this.date_end)}`;
      }

      return '';
    },
  },

  created () {
    this.widgetType = UserWidgetsEnum.open_invoices;
    this.title = UserWidgetsLabels[UserWidgetsEnum.open_invoices];

    this.fetchWrapper();
  },

  methods: {
    async fetchWrapper () {
      try {
        this.filterLoading = true;

        const params: { name: string; value: string }[] = [];

        if (this.date_start) {
          params.push({ name: 'date_start', value: this.date_start })
        }
        if (this.date_end) {
          params.push({ name: 'date_stop', value: this.date_end });
        }

        const { data } = await InvoiceService.getOpenInvoices({
          token: this.token,
          params
        });

        this.items = data.map(item => {
          const { status, color } = getInvoiceStatusProps(item);
          const { daysDiff, diffLabel } = getInvoiceAging(item.due_date);
          return {
            ...item,
            status,
            color,
            daysDiff,
            diffLabel
          };
        });
      } catch (error: any) {
        this.addNotification(error);
      } finally {
        this.filterLoading = false;
        this.loading = false;
      }
    },
    async exportHandler () {
      try {
        this.exportLoading = true;

        const payload = {
          record_type: ExportRecordType.open_invoices,
          type: ExportType.list_export,
          records: this.items.map(item => item._key),
          date_start: this.date_start || null,
          date_stop: this.date_end || null
        };

        await SavedSetService.exportToExcelNew(payload);
        this.addNotification({
          status: 200,
          title: 'Success',
          message: 'Your export <b>is being generated</b>. When <b>complete</b>, a notification <b>will appear</b> at the <b>top right</b> of the screen. ' +
            'Some exports take a few minutes to generate.',
        });
      } catch (error: any) {
        this.addNotification({ ...error });
      } finally {
        this.exportLoading = false;
      }
    },
    clearFilter () {
      this.date_start = null;
      this.date_end = null;
    },
    applyFilter () {
      (this.$refs.menu as any).save();
      this.fetchWrapper();
    },
  }
});
