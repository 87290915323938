import { ITextValue } from '@/types/common';

export const railLines: ITextValue<string>[] = [
  { text: 'Union Pacific', value: 'UP' },
  { text: 'BNSF', value: 'BNSF' },
  { text: 'Kansas City Southern', value: 'KCS' },
  { text: 'Dallas Garland & Northeastern', value: 'DGNO' },
  { text: 'Fort Worth & Western', value: 'FWWR' },
  { text: 'Canadian National', value: 'CN' },
  { text: 'CSX', value: 'CSX' },
  { text: 'Norfolk Southern', value: 'NS' },
];
