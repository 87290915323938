import { filtersConfig } from './filterConfig';

export const generateAvailableFilters = (source) => {
  switch (source) {
    case 'companies':
      return filtersConfig.company.availableFilters;
    case 'contacts':
      return filtersConfig.contact.availableFilters;
    case 'occupiers':
      return filtersConfig.occupier.availableFilters;
    case 'requirements':
      return filtersConfig.requirement.availableFilters;
    case 'availabilities':
      return filtersConfig.availability.availableFilters;
    case 'properties':
      return filtersConfig.property.availableFilters;
    case 'comparables':
      return filtersConfig.comparable.availableFilters;
    case 'pipelines':
      return filtersConfig.pipeline.availableFilters;
    case 'members':
      return filtersConfig.member.availableFilters;
    case 'requests':
      return filtersConfig.request.availableFilters;
    case 'saved Sets':
      return filtersConfig.savedSet.availableFilters;
    case 'eblasts':
      return filtersConfig.eblast.availableFilters
    case 'pressreleases':
      return filtersConfig.pressRelease.availableFilters;
    default:
      return {};
  }
}

export const expandedFiltersName = (source) => {
  switch (source) {
    case 'companies':
      return filtersConfig.company.expandedFiltersName;
    case 'contacts':
      return filtersConfig.contact.expandedFiltersName;
    case 'occupiers':
      return filtersConfig.occupier.expandedFiltersName;
    case 'requirements':
      return filtersConfig.requirement.expandedFiltersName;
    case 'availabilities':
      return filtersConfig.availability.expandedFiltersName;
    case 'properties':
      return filtersConfig.property.expandedFiltersName;
    case 'comparables':
      return filtersConfig.comparable.expandedFiltersName;
    case 'pipelines':
      return filtersConfig.pipeline.expandedFiltersName;
    case 'members':
      return filtersConfig.member.expandedFiltersName;
    case 'requests':
      return filtersConfig.request.expandedFiltersName;
    case 'saved Sets':
      return filtersConfig.savedSet.expandedFiltersName;
    case 'eblasts':
      return filtersConfig.eblast.expandedFiltersName;
    default:
      return {};
  }
}

export const generateAutocompleteItems = (source) => {
  switch (source) {
    case 'contacts':
      return filtersConfig.contact.autocompleteItems;
    case 'properties':
      return filtersConfig.property.autocompleteItems;
    case 'occupiers':
      return filtersConfig.occupier.autocompleteItems;
    case 'requirements':
      return filtersConfig.requirement.autocompleteItems;
    case 'availabilities':
      return filtersConfig.availability.autocompleteItems;
    case 'pipelines':
      return filtersConfig.pipeline.autocompleteItems;
    case 'comparables':
      return filtersConfig.comparable.autocompleteItems;
    default:
      return [];
  }
}

export const generateSelectItems = (source) => {
  switch (source) {
    case 'contacts':
      return filtersConfig.contact.selectItems;
    case 'properties':
      return filtersConfig.property.selectItems;
    case 'availabilities':
      return filtersConfig.availability.selectItems;
    case 'pipelines':
      return filtersConfig.pipeline.selectItems;
    case 'occupiers':
      return filtersConfig.occupier.selectItems;
    case 'comparables':
      return filtersConfig.comparable.selectItems;
    case 'members':
      return filtersConfig.member.selectItems;
    case 'requests':
      return filtersConfig.request.selectItems;
    case 'saved Sets':
      return filtersConfig.savedSet.selectItems;
    case 'industry':
      return filtersConfig.company.selectItems;
    case 'company_industry_group':
      return filtersConfig.company.selectItems;
    default:
      return [];
  }
}

export const getGlobalFilters = () => ([
  filtersConfig.company.getGlobalFilters(),
  filtersConfig.contact.getGlobalFilters(),
  filtersConfig.occupier.getGlobalFilters(),
  filtersConfig.requirement.getGlobalFilters(),
  filtersConfig.property.getGlobalFilters(),
  filtersConfig.availability.getGlobalFilters(),
  filtersConfig.comparable.getGlobalFilters(),
  filtersConfig.project.getGlobalFilters(),
  filtersConfig.savedSet.getGlobalFilters(),
  filtersConfig.pipeline.getGlobalFilters(),
  filtersConfig.deal.getGlobalFilters(),
  filtersConfig.member.getGlobalFilters(),
  filtersConfig.request.getGlobalFilters(),
  filtersConfig.pressRelease.getGlobalFilters()
]);
