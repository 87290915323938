import Vue from 'vue';
import { mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';

interface IAppMixin {
  LocalizationEnum: typeof LocalizationEnum;
}

export const AppMixin = Vue.extend({
  data: (): IAppMixin => ({
    LocalizationEnum
  }),

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
  }
});
