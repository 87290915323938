import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/imports';

export default {
  get: async function ({ id, token, params = [] }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/contacts/${ id }${ paramsString }`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  importContacts: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/contacts`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  importDeals: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/deals`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  processContacts: async function ({ token, id, payload }) {
    try {
      return await instance.post(`${resource}/${id}/process`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          console.debug(progressEvent, 'progress')
        },
        onDownloadProgress: (progressEvent) => {
          console.debug(progressEvent, 'progress download')
        },
      })
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  processDeals: async function ({ token, id, payload }) {
    try {
      return await instance.post(`${resource}/${id}/process-deals`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getReapps: async function ({ token, reportType }) {
    try {
      return await instance.get(`${resource}/reapps?reportType=${reportType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
