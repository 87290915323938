import Account from '@/bundles/Account/models/Account';
import EnterpriseToMapper from '@/bundles/Account/mappers/EnterpriseToMapper';

export default class AccountToMapper {
  mapToModel (data: any): Account {
    const account = new Account();
    account.id = data.id;
    account.legal_name = data.legal_name;
    account.name = data.name;
    account.zip = data.zip;
    account.fax = data.fax;
    account.phone = data.phone;
    account.country = data.country;
    account.state = data.state;
    account.is_active = data.is_active;
    account.address1 = data.address1;
    account.address2 = data.address2;
    account.domain = data.domain;
    account.city = data.city;
    account.account_type = data.account_type;
    account.logo = data.logo;
    account.email_logo = data.email_logo;
    account.alt_text = data.alt_text;
    account.square_logo = data.square_logo;
    account.watermark = data.watermark;
    account.property_placeholder = data.property_placeholder;
    account.enterprise = new EnterpriseToMapper().mapToModel(data.enterprise);
    account.public_logo = data.public_logo;
    account.contact_placeholder = data.contact_placeholder;
    account.is_brokerage = data.is_brokerage;
    account.is_integrated_services = data.is_integrated_services;
    account.primary_color = data.primary_color;
    account.secondary_color = data.secondary_color;
    account.custom_marker = data.custom_marker;
    account.marker_color = data.marker_color;
    return account;
  }
}
