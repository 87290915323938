import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const initialState = {
  constructionType: [
    'Ground Up',
    'Major Modifications',
    'Minor Modifications',
    'Other',
  ],
  buildingType: ['Industrial', 'Office', 'Retail', 'Multifamily'],
  taskTemplate: [
    {
      name: 'Strategic Planning',
      duration: null,
      difference: -85,
      start: null,
      subtasks: [
        {
          name: 'Interviews & Tour Existing Facility',
          duration: 1,
          difference: -85,
          start: null,
        },
        {
          name: 'Define Final Existing Lease Month',
          duration: 2,
          difference: -85,
          start: null,
        },
        {
          name: 'Architect Selection',
          duration: 1,
          difference: -84,
          start: null,
        },
        {
          name: 'Market Research & Analysis',
          duration: 2,
          difference: -83,
          start: null,
        },
        {
          name: 'Prepare Market Survey',
          duration: 2,
          difference: -84,
          start: null,
        },
        {
          name: 'Windshield Tour All Options',
          duration: 1,
          difference: -82,
          start: null,
        },
        {
          name: 'Evaluate & Qualify Options',
          duration: 2,
          difference: -82,
          start: null,
        },
      ],
    },
    {
      name: 'First Building Site Tour',
      duration: null,
      difference: -81,
      start: null,
      subtasks: [
        {
          name: 'Preliminary Building Site Visits',
          duration: 1,
          difference: -81,
          start: null,
        },
        {
          name: 'Preliminary Plan Analysis',
          duration: 2,
          difference: -81,
          start: null,
        },
      ],
    },
    {
      name: 'Initial RFP / LOI',
      duration: null,
      difference: -79,
      start: null,
      subtasks: [
        {
          name: 'Prepare & Deliver RFP / LOI to Qualified Sites',
          duration: 1,
          difference: -79,
          start: null,
        },
        {
          name: 'Response from Landlord / Owner',
          duration: 2,
          difference: -78,
          start: null,
        },
        {
          name: 'Qualitative & Quantitative Analysis',
          duration: 2,
          difference: -76,
          start: null,
        },
      ],
    },
    {
      name: 'Final Building Selection',
      duration: null,
      difference: -74,
      start: null,
      subtasks: [
        {
          name: 'Second Building Tour',
          duration: 1,
          difference: -74,
          start: null,
        },
        {
          name: 'Preliminary Space Plan',
          duration: 2,
          difference: -74,
          start: null,
        },
        {
          name: 'Construction Cost Estimate',
          duration: 1,
          difference: -72,
          start: null,
        },
        {
          name: 'Analysis Refined',
          duration: 1,
          difference: -71,
          start: null,
        },
        {
          name: 'Proposal Review',
          duration: 2,
          difference: -71,
          start: null,
        },
      ],
    },
    {
      name: 'Revised Letter of Intent',
      duration: null,
      difference: -70,
      start: null,
      subtasks: [
        {
          name: 'Delivery of Revised LOI',
          duration: 2,
          difference: -70,
          start: null,
        },
        {
          name: 'Response from Landlord / Seller',
          duration: 1,
          difference: -69,
          start: null,
        },
        {
          name: 'Proposal Analysis',
          duration: 1,
          difference: -68,
          start: null,
        },
      ],
    },
    {
      name: 'Letter of Intent',
      duration: null,
      difference: -68,
      start: null,
      subtasks: [
        {
          name: 'Final Negotiations',
          duration: 3,
          difference: -68,
          start: null,
        },
        {
          name: 'Execution By Both Parties',
          duration: 2,
          difference: -66,
          start: null,
        },
      ],
    },
    {
      name: 'Purchase Contract',
      duration: null,
      difference: -65,
      start: null,
      subtasks: [
        {
          name: 'Preparation & Review',
          duration: 2,
          difference: -65,
          start: null,
        },
        {
          name: 'Contract Negotiation',
          duration: 3,
          difference: -63,
          start: null,
        },
        {
          name: 'Execution By Both Parties',
          duration: 1,
          difference: -61,
          start: null,
        },
        {
          name: 'Due Diligence',
          duration: 8,
          difference: -60,
          start: null,
        },
        {
          name: 'Closing',
          duration: 4,
          difference: -52,
          start: null,
        },
      ],
    },
    {
      name: 'Lease Agreement',
      duration: null,
      difference: -65,
      start: null,
      subtasks: [
        {
          name: 'Preparation & Review',
          duration: 2,
          difference: -65,
          start: null,
        },
        {
          name: 'Lease Negotiations',
          duration: 3,
          difference: -63,
          start: null,
        },
        {
          name: 'Execution By Both Parties',
          duration: 1,
          difference: -61,
          start: null,
        },
      ],
    },
    {
      name: 'Construction Documents (building)',
      duration: null,
      difference: -62,
      start: null,
      subtasks: [
        {
          name: 'Final Space Plan & Approvals',
          duration: 2,
          difference: -62,
          start: null,
        },
        {
          name: 'Construction Documents & Specs',
          duration: 8,
          difference: -61,
          start: null,
        },
        {
          name: 'MEP Documents',
          duration: 4,
          difference: -56,
          start: null,
        },
        {
          name: 'CD & MEP Review & Approvals',
          duration: 1,
          difference: -52,
          start: null,
        },
        {
          name: 'Bid Package & Contractor Selection',
          duration: 1,
          difference: -52,
          start: null,
        },
        {
          name: 'Bid Preparation',
          duration: 1,
          difference: -51,
          start: null,
        },
        {
          name: 'Permitting',
          duration: 6,
          difference: -53,
          start: null,
        },
        {
          name: 'Bid Review & Contract Award',
          duration: 3,
          difference: -50,
          start: null,
        },
      ],
    },
    {
      name: 'Construction Documents (civil)',
      duration: null,
      difference: -58,
      start: null,
      subtasks: [
        {
          name: 'Survey / Typography ',
          duration: 4,
          difference: -58,
          start: null,
        },
        {
          name: 'Mass Grading Plans',
          duration: 3,
          difference: -54,
          start: null,
        },
        {
          name: 'Utitlity Design',
          duration: 3,
          difference: -51,
          start: null,
        },
        {
          name: 'Municipal Approvals',
          duration: 3,
          difference: -48,
          start: null,
        },
        {
          name: 'Civil Plan Generation',
          duration: 3,
          difference: -45,
          start: null,
        },
        {
          name: 'Civil Plans Review & Finalize',
          duration: 2,
          difference: -42,
          start: null,
        },
        {
          name: 'Civil Permitting',
          duration: 3,
          difference: -40,
          start: null,
        },
      ],
    },
    {
      name: 'Construction',
      duration: null,
      difference: -49,
      start: null,
      subtasks: [
        {
          name: 'Site Development',
          duration: 14,
          difference: -13,
          start: null,
        },
        {
          name: 'Shell Construction',
          duration: 36,
          difference: -49,
          start: null,
        },
        {
          name: 'Interior Finish Out',
          duration: 14,
          difference: -13,
          start: null,
        },
        {
          name: 'Punch List',
          duration: 2,
          difference: -1,
          start: null,
        },
      ],
    },
    {
      name: 'Move Coordination',
      duration: null,
      difference: -61,
      start: null,
      subtasks: [
        {
          name: 'Mover Selection',
          duration: 1,
          difference: -47,
          start: null,
        },
        {
          name: 'Telephone / Computer Selection',
          duration: 7,
          difference: -59,
          start: null,
        },
        {
          name: 'Telephone / Computer Installation',
          duration: 2,
          difference: -1,
          start: null,
        },
        {
          name: 'Furniture Selection',
          duration: 8,
          difference: -61,
          start: null,
        },
        {
          name: 'Furniture Delivery & Installation',
          duration: 2,
          difference: -1,
          start: null,
        },
        {
          name: 'Actual Physical Move In',
          duration: 2,
          difference: 0,
          start: null,
        },
        {
          name: 'Order Racking / Forklifts / Material Handling',
          duration: 1,
          difference: -59,
          start: null,
        },
        {
          name: 'Resolution of Change Orders/Punch List',
          duration: 3,
          difference: 0,
          start: null,
        },
        {
          name: 'Final Payment to Contractor',
          duration: 1,
          difference: 3,
          start: null,
        },
      ],
    },
  ],
  projects: [],
  fullscreen: false,
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
}
