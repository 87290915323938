const getMsaLayers = (state) => (type) => state.layers.filter((layer) => layer.source && layer.source.includes(type));

const getLayers = (state) => () => state.layers;

const getLayersByType = (state) => (type) => state.layersNew[type];

const getLayerByType = (state) => (type) =>
  state.layers.filter((layer) => layer.source_type && layer.source_type.includes(type)
  );
const getMarketLayers = (state) => (type) =>
  state.layers.filter(
    (layer) =>
      layer.source_type &&
      layer.source_type.includes(type) &&
      !layer.source_type.includes('cluster')
  );

const getStandardLayerById = (state) => (id) => state.standardLayers.find(layer => layer.id === id) || {};

const getCustomLayerTypes = (state) => () => {
  return Object.keys(state.layersNew).filter(item => item !== 'system' && item !== 'standard');
};

const getCustomLayerSections = (state) => (layerType) => {
  return state.layersNew[layerType] || [];
}

export default {
  getLayers,
  getLayersByType,
  getLayerByType,
  getMarketLayers,
  getMsaLayers,
  getStandardLayerById,
  getCustomLayerTypes,
  getCustomLayerSections
}
