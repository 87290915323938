











import Vue from 'vue';
import { ServiceFactory } from '@/services/ServiceFactory';
import { mapMutations, mapState } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';

import ConfidentialCheckboxWrapper from '@/bundles/Deal/components/confidential/ConfidetialCheckboxWrapper.vue';

const DealsService = ServiceFactory.get('deals');

export default Vue.extend({
  name: 'DealConfidentialCheckboxWrapper',

  components: { ConfidentialCheckboxWrapper },

  props: {
    dealId: {
      type: [String, Number],
      required: true,
    },
    entryConfidential: Boolean,
  },

  data: (): { isLoading: boolean; } => ({
    isLoading: false,
  }),

  computed: {
    ...mapState({
      token: (state: any): string => state.idToken,
    }),
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    async confidentialUpdateConfirmHandler ({ confidential, updateRelated }: { confidential: boolean, updateRelated: boolean }) {
      try {
        this.isLoading = true;

        await DealsService.update({
          id: this.dealId,
          token: this.token,
          payload: { confidential, update_related_comparable: updateRelated },
        });

        this.$emit('update:confidential', confidential);
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.isLoading = false;
      }
    },
  }
})
