import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { INameValue } from '@/types/common';
import { ITableOptions } from '@/bundles/BaseTable/interfaces';
import { getTableOptions } from '@/bundles/BaseTable/helpers/consts';
import { NotificationMutations } from '@/store/types/mutation-types';

interface IRecordsCardMixinData {
  items: any[];
  tableOptions: ITableOptions;
  showCreateDialog: boolean;
  loading: boolean;
  filterLoading: boolean;
  total: number;
  params: INameValue<any>[];
}

export const RecordsCardMixin = Vue.extend({
  name: 'RecordsCardMixin',

  props: {
    allowAdd: Boolean,
    sourceType: {
      type: String,
      required: true,
    },
    sourceId: {
      type: String,
      required: true,
    },
  },

  data: (): IRecordsCardMixinData => ({
    items: [],
    tableOptions: getTableOptions({ itemsPerPage: 10 }),
    showCreateDialog: false,
    loading: true,
    filterLoading: false,
    total: 0,
    params: []
  }),

  computed: {
    ...mapState({
      token: (state: any) => state.idToken
    }),
    pageOffset: function (): number {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
    },
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION
    }),
    toggleCreateDialog (value: boolean) {
      this.showCreateDialog = value;
    }
  }
});
