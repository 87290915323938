



















import Vue from 'vue';
import { mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';

import ConfidentialCheckbox from '@/bundles/Deal/components/confidential/ConfidentialCheckbox.vue';
import ConfidentialConfirmDialog from '@/bundles/Deal/components/confidential/ConfidentialConfirmDialog.vue';

interface IConfidentialMixin {
  showDialog: boolean;
  confidential: boolean;
}

export default Vue.extend({
  name: 'ConfidentialCheckboxWrapper',

  components: {
    ConfidentialConfirmDialog,
    ConfidentialCheckbox
  },

  props: {
    entryConfidential: Boolean,
    isLoading: Boolean,
    source: {
      type: String,
      required: true,
    },
    updateRelatedText: {
      type: String,
      required: true,
    },
  },

  data: (): IConfidentialMixin => ({
    showDialog: false,
    confidential: false,
  }),

  watch: {
    entryConfidential: {
      handler (value: boolean) {
        this.confidential = value;
      },
      immediate: true
    }
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    cancelHandler () {
      this.showDialog = false;

      // hack to reset checkbox state
      this.$emit('update:confidential', this.confidential);
      this.$nextTick(() => {
        this.$emit('update:confidential', !this.confidential);
      });
    },
    updateHandler ({ confidential, updateRelated }: { confidential: boolean, updateRelated: boolean }) {
      this.$emit('submit:confidential', { confidential, updateRelated });
      this.showDialog = false;
    },
    updateCheckboxHandler (value: boolean) {
      this.confidential = value;
      this.showDialog = true;
    },
  }
})
