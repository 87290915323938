
import { JobFunction, JobFunctionLabels } from '@/bundles/Members/enums';

export const availableFilters = {
  job_function: 'select',
  employee_category: 'select',
  status: 'select',
};

export const expandedFiltersName = {
  job_function: 'Job Function',
  employee_category: 'Employee Category',
  status: 'Status',
};

export const selectItems = {
  status: [
    { text: 'Active', value: 'active' },
    { text: 'Inactive', value: 'inactive' },
  ],
  employee_category: [
    { text: 'Full Time', value: 'Full Time' },
    { text: 'Part Time', value: 'Part Time' },
  ],
  job_function: [
    { text: JobFunctionLabels[JobFunction.salesperson], value: JobFunction.salesperson },
    { text: JobFunctionLabels[JobFunction.coordinator], value: JobFunction.coordinator },
    { text: JobFunctionLabels[JobFunction.research], value: JobFunction.research },
    { text: JobFunctionLabels[JobFunction.manager], value: JobFunction.manager },
    { text: JobFunctionLabels[JobFunction.marketing], value: JobFunction.marketing },
    { text: JobFunctionLabels[JobFunction.it], value: JobFunction.it },
    { text: JobFunctionLabels[JobFunction.intern], value: JobFunction.intern },
    { text: JobFunctionLabels[JobFunction.finance], value: JobFunction.finance },
    { text: JobFunctionLabels[JobFunction.data_entry], value: JobFunction.data_entry },
    { text: JobFunctionLabels[JobFunction.data_analyst], value: JobFunction.data_analyst },
    { text: JobFunctionLabels[JobFunction.executive], value: JobFunction.executive },
    { text: JobFunctionLabels[JobFunction.admin], value: JobFunction.admin }
  ],
};

export const getGlobalFilters = () => ({
  group: 'member',
  model: false,
  isActive: false,
  icon: 'mdi-account-multiple',
  title: 'Members',
  children: [
    {
      icon: 'mdi-adjust',
      title: 'Locations',
      name: 'primary_locations',
      type: 'office_location',
    },
    {
      icon: 'mdi-adjust',
      title: 'Job Function',
      name: 'member_job_function',
      type: 'select',
      items: selectItems.job_function
    },
    {
      icon: 'mdi-adjust',
      title: 'Employee Type',
      items: [
        { text: 'Contractor', value: 'contractor' },
        { text: 'Employee', value: 'employee' },
        { text: 'Temporary', value: 'temporary' },
      ],
      format: 'currency',
      name: 'member_employee_type',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Employee Category',
      items: [
        { text: 'Full Time', value: 'Full Time' },
        { text: 'Part Time', value: 'Part Time' },
      ],
      format: 'currency',
      name: 'member_employee_category',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Include External Members',
      items: [],
      format: 'currency',
      name: 'member_include_external',
      type: 'switch',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'member_view',
      type: 'create_updated_select',
    },
    {
      title: 'Filtered Members',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
