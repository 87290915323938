export const availableFilters = {
  show_my_teams: 'boolean',
  record_type: 'select',
};

export const expandedFiltersName = {
  show_my_teams: 'Saved by me/my team',
  record_type: 'Record Type',
};

export const selectItems = {
  record_type: [
    { text: 'Companies', value: 'companies' },
    { text: 'Contacts', value: 'contacts' },
  ],
};

export const getGlobalFilters = () => ({
  group: 'savedsets',
  model: false,
  isActive: false,
  icon: 'mdi-table',
  title: 'Saved Sets',
  children: [
    {
      icon: 'mdi-adjust',
      title: 'Record / Section',
      items: [
        { text: 'Company', value: 'company' },
        { text: 'Contact', value: 'contact' },
        { text: 'Occupier', value: 'occupier' },
        { text: 'Requirement', value: 'requirement' },
        { text: 'Property', value: 'property' },
        { text: 'Availability', value: 'availability' },
        { text: 'Comparable', value: 'comparable' },
        { text: 'Project', value: 'project' },
        { text: 'Saved Set', value: 'savedset' },
        { text: 'Pipeline', value: 'pipeline' },
        { text: 'Deal', value: 'deal' },
        { text: 'Marketing', value: 'marketing' }
      ],
      format: 'currency',
      name: 'set_section',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'set_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'set_view',
      type: 'create_updated_select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Featured',
      items: ['', ''],
      name: 'set_featured',
      type: 'switch',
    },
    {
      icon: 'mdi-adjust',
      title: 'System Set',
      name: 'is_system_set',
      type: 'switch',
    },
    {
      title: 'Filtered Saved Sets',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ]
});
