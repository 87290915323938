




































































import Vue from 'vue';
import { Nullable } from '@/utils/types';
import { DateTime } from 'luxon';
import { mapState } from 'vuex';

interface ICommonDatePicker {
  date: Nullable<string>;
  dateFormatted: Nullable<string>;
  menu: boolean;
  isFirstLoad: boolean;
}

const momentToLuxonFormatMapper = {
  'mm/dd/yyyy': 'LL/dd/yyyy',
  'dd/mm/yyyy': 'dd/LL/yyyy',
  'd/m/yyyy': 'd/L/yyyy',
  'm/d/yyyy': 'L/d/yyyy',
}

export default Vue.extend({
  name: 'CommonDatePicker',

  props: {
    readonly: Boolean,
    enterDate: {
      type: [String, Number, Date],
      default: ''
    },
    label: {
      type: String,
      default: 'Date'
    },
    disabled: Boolean,
    disabledMessage: {
      type: String,
      default: ''
    },
    filled: Boolean,
    outlined: Boolean,
    flat: Boolean,
    dense: Boolean,
    clearable: Boolean,
    classList: {
      type: String,
      default: ''
    },
    nudgeRight: {
      type: Number,
      default: 0
    },
    nudgeLeft: {
      type: Number,
      default: 0
    },
    rules: {
      type: Array,
      default: () => []
    },
    error: Boolean,
    hideDetails: {
      type: Boolean,
      default: true
    },
  },

  data: () : ICommonDatePicker => ({
    date: '',
    dateFormatted: null,
    menu: false,
    isFirstLoad: true,
  }),

  computed: {
    ...mapState({
      datePickerFormat: (state: any) => state.accountSettings.date_picker_format,
    }),
    dateRules () {
      return [
        ...this.rules,
        (value: string) => {
          if (!value) return true;

          return this.checkValidDate(value).isValid || 'Invalid date';
        }
      ]
    },
  },

  watch: {
    date: function (val: Nullable<string>) {
      this.dateFormatted = this.formatDate(val);
      this.$emit('updateDate', val);
    },
    enterDate: {
      handler: function (val: Nullable<string | number | Date>) {
        if (this.isFirstLoad && !val) {
          this.isFirstLoad = false;
          return;
        }

        if (typeof val === 'number') {
          val = DateTime.fromMillis(val).toFormat('yyyy-LL-dd');
        } else if (val instanceof Date) {
          val = DateTime.fromJSDate(val).toFormat('yyyy-LL-dd');
        }

        this.date = (val as string);
      },
      immediate: true,
    }
  },

  methods: {
    formatDate (date): string | null {
      if (!date) return null;
      return this.$options.filters?.formatDate(date);
    },
    getISODate (date: Nullable<string>): string {
      if (!date) { return '' }

      return this.checkValidDate(date).date;
    },
    checkValidDate (date: string) {
      const format = momentToLuxonFormatMapper[this.datePickerFormat.toLowerCase()] || 'LL/dd/yyyy';

      const [input1, input2, input3] = date.split('/');
      const paddedDate = `${input1?.padStart(2, '0')}/${input2?.padStart(2, '0')}/${input3?.padStart(4, '20')}`;

      const dateToCheck = DateTime.fromFormat(paddedDate, format);

      if (!dateToCheck.isValid) {
        return {
          date: this.date as string,
          isValid: false,
        };
      }

      return {
        date: dateToCheck.toISODate(),
        isValid: true,
      };
    }
  },
});
