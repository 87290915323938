import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { getMinMaxLabel } from '@/bundles/Common/helpers';
import { formatRawInteger, formatRawNumber } from '@/utils/filters';

export * from './finishCondition';
export * from './railDetailsHelpers';

export const floorPlateValidation = (value) => {
  if (value) {
    return formatRawNumber(value) === formatRawInteger(value) || 'Value should be integer.';
  }
  if (value === '' || value === null || value === 0) {
    return true;
  }

  return 'Please Enter integer value';
};

export const getPropertyBuildingDimensionsLabel = (dimension = { width: { min: null, max: null }, depth: { min: null, max: null } }) => {
  const { width, depth } = dimension;
  if (!isValuePresent(!width.min) && !isValuePresent(width.max) && !isValuePresent(depth.min) && !isValuePresent(depth.max)) {
      return null;
  }

  return `${getMinMaxLabel(dimension.width.min, dimension.width.max)} x ${getMinMaxLabel(dimension.depth.min, dimension.depth.max)}`;
};
