import * as popupContentBuilder from '@/bundles/Map/helpers/popupContentBuilder';
import { placeholderPropertyImg } from '@/settings';
import { ITextValue } from '@/types/common';
import { formatCurrency, formatCurrencyNoDecimal, formatDate } from '@/utils/filters';

const sourceType = 'availability';

export const generateMapFeatures = (availabilities, formatters) => {
  return availabilities.filter((availability) => {
    const coordinates = availability.coordinates?.lat && availability.coordinates?.lng ? availability.coordinates
      : availability.property?.coordinates;

    return !!coordinates?.lat && !!coordinates?.lng;
  }).map((availability) => {
    const coordinates = availability.coordinates?.lat && availability.coordinates?.lng ? availability.coordinates
      : availability.property?.coordinates;

    const transactionType = availability.for_sale && availability.for_lease ? 'Lease / Sale'
      : availability.for_sale ? 'Sale' : availability.for_lease ? 'Lease' : 'N/A';

    const askingPrice = availability.will_not_sell ? 'WNS'
      : availability?.sale?.private ? 'Private'
      : availability?.sale?.asking_price ? formatCurrencyNoDecimal(availability.sale.asking_price) : 'N/A';

    const leaseRate = availability?.lease?.private ? 'Private'
      : availability?.lease_rate && availability?.lease_type ? `${formatCurrency(availability.lease_rate)} ${availability.lease_type}`
      : 'N/A';

    let officeSize = formatters.buildingSize ? formatters.buildingSize(availability.office_space) : availability.office_space;

    if (availability.bts) officeSize = 'BTS';

    return {
      id: availability._key || availability.id,
      number: availability.number,
      place_name: `${
        availability.address?.postal ||
        availability.property.home_address?.home_postal
      }, ${
        availability.address?.street ||
        availability.property.home_address?.home_street
      }, ${
        availability.address?.city || availability.property.home_address?.home_city
      }, ${
        availability.address?.state || availability.property.home_address?.home_state
      }, ${availability.address?.country || ''}`,
      text:
        availability.property.name,
      center: [ coordinates.lng, coordinates.lat ],
      place_type: 'availability',
      place_photo: availability?.photo?.url || placeholderPropertyImg,
      type: 'Feature',
      geometry: {
        type: 'Point',
        interpolated: true,
        coordinates: [ coordinates.lng, coordinates.lat ],
      },
      properties: {
        number: availability.number,
        place_name: `${
          availability.address?.postal ||
          availability.property.home_address?.home_postal
        }, ${
          availability.address?.street ||
          availability.property.home_address?.home_street
        }, ${
          availability.address?.city || availability.property.home_address?.home_city
        }, ${
          availability.address?.state ||
          availability.property.home_address?.home_state
        }, ${availability.address?.country || ''}`,
        text:
          availability.property.name,
        image: availability?.photo?.url || placeholderPropertyImg,
        status: availability.status,
        sale: availability.sale,
        lease: availability.lease,
        ti_allowance: formatCurrency(availability.ti_allowance),
        available_space: availability.available_space,
        divisible_space: availability.divisible_space > 0 ? availability.divisible_space : null,
        bts: availability.bts,
        office_space: officeSize,
        acres: availability.acres,
        date_available: availability.date_available ? formatDate(availability.date_available) : 'N/A',
        date_on_market: availability.date_on_market ? formatDate(availability.date_on_market) : 'N/A',
        agency_type: availability.agency_type,
      },
      image: availability.photo,
      square_feet: availability.available_space,
      address: availability.property.address,
      slab_thickness: availability.property.slab_thickness,
      slab_psi: availability.property.slab_psi,
      availability_type: availability.type,
      transaction_type: transactionType,
      in_house: availability.in_house,
      true_owner: availability.true_owner || [],
      available_space: formatters.buildingSize && availability.available_space > 0 ? formatters.buildingSize(availability.available_space) : null,
      divisible_space: formatters.buildingSize && availability.divisible_space > 0 ? formatters.buildingSize(availability.divisible_space) : null,
      office_space: officeSize,
      acres: formatters.landSize ? formatters.landSize(availability.acres) : availability.acres,
      divisible_land_size: formatters.buildingSize && availability.divisible_land_size > 0 ? formatters.landSize(availability.divisible_land_size) : null,
      property: availability.property || 'N/A',
      for_sale: availability.for_sale,
      for_lease: availability.for_lease,
      asking_price: askingPrice,
      lease_rate: leaseRate
    }
  });
};

const getBodyContent = (availability: any) => {
  const content: ITextValue<string>[] = [
    { text: 'Status', value: availability.properties.status },
    { text: 'Property Type', value: availability.availability_type },
    { text: 'Transaction Type', value: availability.transaction_type },
    { text: 'Land Size', value: availability.acres },
    { text: 'Divisible Land Size', value: availability.divisible_land_size },
    { text: 'Available Space', value: availability.available_space },
    { text: 'Divisible Space', value: availability.divisible_space },
  ];

  if (availability.availability_type === 'Industrial') {
    content.push({ text: 'Office Size', value: availability.office_space });
  }

  content.push({ text: 'TI Allowance', value: availability.properties.ti_allowance });
  content.push({ text: 'Construction Status', value: availability.property.construction_status });
  content.push({ text: 'Data Available', value: availability.properties.date_available });
  content.push({ text: 'Data On Market', value: availability.properties.date_on_market });

  if (availability.for_sale) {
    content.push({ text: 'Asking Price', value: availability.asking_price });
  }

  if (availability.for_lease) {
    content.push({ text: 'Lease Rate', value: availability.lease_rate });
  }

  return content;
}

const getAvailabilityLabelText = ({ for_sale, for_lease }: any) => {
  switch (true) {
    case for_sale && for_lease: return 'for sale / for lease';
    case for_sale: return 'for sale';
    case for_lease: return 'for lease';
    default: return '';
  }
}

export const getAvailabilityPopupContent = (availability: any) => {
  const container = popupContentBuilder.getContainer();
  const imageSection = popupContentBuilder.getImageSection(availability.properties.image, availability.text || 'Availability Image');
  const idSection = popupContentBuilder.getIdSection(sourceType, availability.id);
  const titleSection = popupContentBuilder.getTitleSection(sourceType, availability.id, availability.text);
  const addressSection = popupContentBuilder.getAddressSection(availability.address);
  const bodySection = popupContentBuilder.getBodySection(getBodyContent(availability));
  const labelText = getAvailabilityLabelText(availability);
  const buildingDetailsSection = popupContentBuilder.getBuildingDetailsSection({ labelText, addressSection, imageSection });

  container.append(buildingDetailsSection, idSection, titleSection, bodySection);

  return container;
};
