export enum FinishConditionEnum {
  shell = 'shell',
  existing = 'existing',
  spec = 'spec',
  whiteBox = 'whiteBox'
}

export enum OwnerShipTypeEnum {
  institutional = 'Institutional',
  private = 'Private'
}
