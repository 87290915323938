<template>
  <v-card
    :outlined="outlined"
    :class="[
      {'sc-record-card--no-pointer sc-record-card--no-hover': !isListView},
      `sc-record-card mx-auto ${cardClass}`
    ]"
    class="d-flex flex-column"
    :flat="isFlat"
    :height="isListView ? '100%' : 'auto'"
    :ripple="false"
    @click="changeSelected"
  >
    <v-card-title v-if="showTitle">Deal</v-card-title>
    <v-divider v-if="showTitle" />
    <v-img
      v-if="showPhoto"
      :src="getDealPhotoUrl"
      :min-height="isListView ? 200 : 'auto'"
      :max-height="isListView ? 200 : 'auto'"
    />
    <v-list :class="cardPadding" class="record-content">
      <v-list-item>
        <v-list-item-content class="pb-0">
          <span class="record-id">Deal ID# {{ itemRecord._key }}</span>
          <v-list-item-title class="headline mb-1">
            <router-link
              v-if="allowLink"
              :to="getAccountRoute({ name: 'deal', params: { id: itemRecord._key }})"
              class="headline mb-1"
            >
              {{ itemRecord.name }}
            </router-link>
            <span v-else>{{ itemRecord.name }}</span>
            <a @click="edit">
              <v-btn
                v-if="isEditable"
                x-small
                class="record-card-edit"
                icon
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </a>
          </v-list-item-title>
          <div v-if="!!itemRecord.contract_pending">
            <CommonChip class="mt-1" color="orange">Contract Pending</CommonChip>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider v-if="showSummary" class="ma-4 mb-0" />
    <v-card-text v-if="showSummary" class="record-card-content">
      <div>
        <strong>{{ $t('transaction_type') }}: </strong>
        <span style="text-transform: capitalize">{{ getDealTransactionTypeLabel(itemRecord) }}</span>
      </div>
      <SaleConditionsLabel
        v-if="isValuePresent(itemRecord.sale_conditions)"
        :sale-conditions="itemRecord.sale_conditions"
      />
      <div>
        <strong>Property Type: </strong>
        <span v-if="propertyType" style="text-transform: capitalize">{{ propertyType }}</span>
        <span v-else class="na">N/A</span>
      </div>
      <div>
        <strong>Transaction Category: </strong>
        <span v-if="transactionCategory">{{ transactionCategory }}</span>
        <span v-else class="na">n/a</span>
      </div>
      <div v-if="'source' in itemRecord">
        <strong>{{ $t(LocalizationEnum.deal_pipeline_source_category) }}: </strong>
        <span>{{ dealSource }}</span>
      </div>
      <div v-if="!!itemRecord.suite">
        <strong>Suite #: </strong>
        <span>{{ itemRecord.suite }}</span>
      </div>
      <div v-if="propertyType === PropertyTypeEnum.retail && itemRecord.property.center_type">
        <strong>{{ $t(LocalizationEnum.retail_center_type) }}: </strong>
        <span>{{ PropertyCenterTypeEnumLabels[itemRecord.property.center_type] }}</span>
      </div>
      <div v-if="isBuildingSizeInObject">
        <strong>Space Size: </strong>
        <BuildingSizeLabel :value="buildingSize" />
      </div>
      <div v-if="isLandSizeInObject">
        <strong>Land Size: </strong>
        <LandSizeLabel :value="landSize" />
      </div>
      <div v-if="isTotalConsiderationInObject">
        <strong>Total Consideration: </strong>
        <span>
          {{ totalConsideration | formatCurrency }}
          <v-btn
            v-if="isEditable"
            icon
            class="float-right"
            @click="refreshConsideration"
          ><v-icon small>mdi-refresh</v-icon></v-btn>
        </span>
      </div>
      <div v-if="isTotalCommissionInObject">
        <strong>Total Commission: </strong>
        {{ totalCommission | formatCurrency }}
      </div>
      <div v-if="itemRecord.in_house_gross">
        <strong>In House Gross: </strong>
        <span>{{ itemRecord.in_house_gross | formatCurrency }}</span>
      </div>
      <div v-if="contacts.length">
        <strong>{{ $t('referring') }}: </strong>
        <span v-if="referringAgentContacts.length">
          <span v-for="(contact, index) in referringAgentContacts" :key="contact._id">
            {{ contact.first_name }} {{ contact.last_name }}<span v-if="index < referringAgentContacts.length - 1">,</span>
          </span>
        </span>
        <Label v-else />
      </div>
      <div v-if="contacts.length">
        <strong>{{ $t('receiving') }}: </strong>
        <span v-if="receivingAgentContacts.length">
          <span v-for="(contact, index) in receivingAgentContacts" :key="contact._id">
            {{ contact.first_name }} {{ contact.last_name }}<span v-if="index < receivingAgentContacts.length - 1">,</span>
          </span>
        </span>
        <Label v-else />
      </div>
      <div v-if="'inbound' in itemRecord || 'outbound' in itemRecord">
        <strong>Inbound / Outbound: </strong>
        <CommonChip v-if="itemRecord.inbound" class="mb-2">
          Inbound
        </CommonChip>
        <span v-if="itemRecord.inbound && itemRecord.outbound" class="px-1" />
        <CommonChip v-if="itemRecord.outbound" class="mb-2">
          Outbound
        </CommonChip>
      </div>
      <div>
        <span v-if="!editTransactionDate">
          <strong>Transaction Date: </strong>
          <span v-if="formattedTransactionDate">{{ formattedTransactionDate }}</span>
          <Label v-else />
        </span>
        <span v-else>
          <v-menu
            v-model="transactionDateMenu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedTransactionDate"
                clearable
                label="Transaction Date"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="cancelTransactionDate"
              />
            </template>
            <!-- eslint-disable -->
            <v-date-picker
              v-model="itemRecord.transaction.transaction_date"
              @change="transactionDateMenu = false"
            />
            <!-- eslint-enable -->
          </v-menu>
        </span>
        <v-btn
          v-if="isEditable && !editTransactionDate"
          icon
          @click="editValue('transaction_date')"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <div v-if="adjustments.length">
        <strong>Adjustments</strong><br>
        <div v-for="(value, index) in adjustments" :key="index">
          {{ value.type }}: {{ value.amount | formatCurrency }}
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                small
                class="cursor-pointer"
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>{{ value.memo }}</span>
          </v-tooltip>
        </div>
      </div>
      <div v-if="'internal_id' in itemRecord">
        <strong>{{ $t(LocalizationEnum.deal_internal_id) }}: </strong>
        {{ itemRecord.internal_id }}
      </div>
      <div v-if="businessLine">
        <strong>{{ $t(LocalizationEnum.business_line) }}: </strong>
        {{ businessLine }}
      </div>
    </v-card-text>
    <v-card-text v-if="confidentialSwitchEnabled">
      <DealConfidentialCheckboxWrapper
        :deal-id="item._key || itemId"
        :entry-confidential="itemRecord.confidential"
        @update:confidential="$emit('update:confidential', $event)"
      />
    </v-card-text>
    <v-card-actions v-if="allowSelect" style="margin-top: auto;">
      <v-btn block class="primary" @click="select">Select Deal</v-btn>
    </v-card-actions>
    <v-card-actions v-if="hasChangedValues && isEditable">
      <v-btn color="primary" small @click="saveChanges">Save Changes</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { ServiceFactory } from '@/services/ServiceFactory';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { formatJSDate } from '@/utils/filters';
import { getSourceLabel } from '@/bundles/Pipelines/helpers';
import { uniqBy } from 'lodash';
import { getDealContactsArray } from '@/bundles/Deal/helpers';
import { PropertyTypeEnum } from '@/bundles/Property/interfaces/PropertyType';
import { PropertyCenterTypeEnumLabels } from '@/bundles/Property/enums/PropertyCenterTypeEnum';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { getDealTransactionTypeLabel } from '@/bundles/Deal/helpers/transaction/getDealTransactionLabel';
import { BusinessLineLabels } from '@/bundles/Pipelines/types';

import Label from '@/bundles/Common/components/Label.vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';
import LandSizeLabel from '@/bundles/MeasurementUnits/components/LandSizeLabel.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';
import DealConfidentialCheckboxWrapper from '@/bundles/Deal/components/confidential/DealConfidetialCheckboxWrapper.vue';
import SaleConditionsLabel from '@/bundles/Comparables/components/labels/SaleConditionsLabel.vue';

const DealService = ServiceFactory.get('deals');

export default {
  name: 'DealCard',

  components: {
    SaleConditionsLabel,
    Label,
    CommonChip,
    DealConfidentialCheckboxWrapper,
    LandSizeLabel,
    BuildingSizeLabel
  },

  mixins: [AccountLayoutMixin],

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    itemId: {
      type: [Number, String],
      default: null,
    },
    isFlat: Boolean,
    outlined: Boolean,
    cardClass: {
      type: String,
      default: '',
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    allowLink: Boolean,
    isListView: Boolean,
    showTitle: Boolean,
    showSummary: {
      type: Boolean,
      default: true
    },
    showPhoto: {
      type: Boolean,
      default: true,
    },
    confidentialSwitchEnabled: Boolean,
    allowSelect: Boolean,
  },

  data: () => ({
    transactionDateMenu: false,
    editTransactionDate: false,
    hasChangedValues: false,
    itemRecord: {},
  }),

  computed: {
    PropertyCenterTypeEnumLabels () {
      return PropertyCenterTypeEnumLabels
    },
    PropertyTypeEnum () {
      return PropertyTypeEnum
    },
    ...mapState({
      token: (state) => state.idToken,
    }),
    buildingSize: function () {
      return this.$options.filters.formatNumber(this.itemRecord.transaction?.total_square_feet);
    },
    isBuildingSizeInObject: function () {
      return this.itemRecord.transaction && 'total_square_feet' in this.itemRecord.transaction;
    },
    landSize: function () {
      return this.itemRecord.transaction?.total_acres || 0;
    },
    isLandSizeInObject: function () {
      return this.itemRecord.transaction && 'total_acres' in this.itemRecord.transaction;
    },
    totalConsideration: function () {
      return this.itemRecord.transaction?.consideration || null;
    },
    isTotalConsiderationInObject: function () {
      return this.itemRecord.transaction && 'consideration' in this.itemRecord.transaction;
    },
    totalCommission: function () {
      return this.itemRecord.transaction?.total_commission || null;
    },
    isTotalCommissionInObject: function () {
      return this.itemRecord.transaction && 'total_commission' in this.itemRecord.transaction;
    },
    propertyType: function () {
      return this.itemRecord.property?.type || null;
    },
    adjustments: function () {
      return this.itemRecord.transaction?.adjustments || []
    },
    transactionCategory: function () {
      return this.itemRecord.category || null;
    },
    cardPadding () {
      if (this.showSummary) {
        return 'pb-0';
      } else {
        return 'pb-4';
      }
    },
    formattedTransactionDate () {
      const date = this.itemRecord.transaction?.transaction_date || this.itemRecord.transaction_date;
      return date ? formatJSDate(date) : '';
    },
    isEditable: function () {
      return (this.isSuperAdmin ||
        this.$store.state.userRoles.indexOf('financial admin') > -1) && this.allowEdit;
    },
    getDealPhotoUrl: function () {
      return this.itemRecord?.photo?.url || this.item?.comparable?.property?.photo?.url || this.$placeholderPropertyImg;
    },
    dealSource () {
      return getSourceLabel(this.itemRecord.source);
    },
    contacts () {
      return getDealContactsArray(this.itemRecord.contacts) || [];
    },
    referringAgentContacts () {
      return uniqBy(this.contacts.filter(contact => !!contact.referring_agent), '_id');
    },
    receivingAgentContacts () {
      return uniqBy(this.contacts.filter(contact => !!contact.receiving_agent), '_id');
    },
    businessLine () {
      return BusinessLineLabels[this.itemRecord.business_line] || null;
    }
  },

  watch: {
    'itemRecord.transaction.transaction_date' (newValue, oldValue) {
      if (this.loaded === true && oldValue && newValue !== oldValue) {
        this.hasChangedValues = true
      }
    },
    item: {
      handler: function () {
        this.itemRecord = this.item
      },
      deep: true,
    },
    itemId: function (newValue, prevValue) {
      if (newValue !== prevValue) {
        this.getDeal()
      }
    }
  },

  created () {
    if (this.itemId) {
      this.getDeal()
    } else {
      this.itemRecord = this.item
      this.loaded = true
    }
  },

  methods: {
    isValuePresent,
    getDealTransactionTypeLabel,
    ...mapMutations('Notifications', ['ADD_NOTIFICATION']),
    getDeal: async function () {
      try {
        this.loaded = false;

        const { data } = await DealService.get({
          token: this.$store.state.idToken,
          id: this.itemId,
        });

        this.itemRecord = data;
      } catch (error) {
        this.addNotification(error);
      } finally {
        this.loaded = true;
        this.hasChangedValues = false;
      }
    },
    edit: function () {
      this.$emit('edit');
    },
    print: function () {
      this.$emit('print');
    },
    editValue: function () {
      this.editTransactionDate = true;
    },
    cancelTransactionDate: function () {
      this.editTransactionDate = false;
    },
    saveChanges: async function () {
      try {
        const payload = {
          transaction: {
            transaction_date: this.itemRecord.transaction?.transaction_date,
          },
        };

        await DealService.updateTransactionDate({
          id: this.itemRecord._key,
          token: this.token,
          payload
        });
        this.editTransactionDate = false
        this.hasChangedValues = false
      } catch (error) {
        this.addNotification({ ...error });
      }
    },
    refreshConsideration: async function () {
      try {
        const { data } = await DealService.updateConsideration({
          id: this.itemRecord._key,
          token: this.token,
        });

        this.editTransactionDate = false;
        this.hasChangedValues = false;
        this.$emit('updateDeal', data);
      } catch (error) {
        this.addNotification({ ...error });
      }
    },
    changeSelected: function () {
      this.$emit('click', this.item)
    },
    select () {
      this.$emit('select', this.item._id);
    },
  },
};
</script>

<style scoped lang="scss">
.v-card__text.record-card-content > div {
  //line-height: 40px;
}
.v-card__text.record-card-content > div .v-btn {
  display: none;
}
.v-card__text.record-card-content > div:hover .v-btn {
  display: initial;
}
</style>
