import { FinishConditionEnum } from '@/bundles/Property/enums';
import { ITextValue } from '@/types/common';

export type FinishConditionLabelsType = { [key in keyof typeof FinishConditionEnum]: string };

export const FinishConditionLabels: FinishConditionLabelsType = {
  [FinishConditionEnum.shell]: 'Shell',
  [FinishConditionEnum.existing]: 'Existing',
  [FinishConditionEnum.spec]: 'Spec.',
  [FinishConditionEnum.whiteBox]: 'White box'
};

export const getFinishConditionSelectItems = (): ITextValue<FinishConditionEnum>[] => {
  return Object.values(FinishConditionEnum).map(item => ({
    text: FinishConditionLabels[item],
    value: item
  }));
};
