import * as constants from './filters-types';
import { cloneDeep } from 'lodash';

export default {
  [constants.SET_FILTERS_DATA] (state: any, { key, data }: { key: string; data: any }) { // TODO: add type
    state[key] = cloneDeep(data);
  },
  [constants.SET_IS_FILTERS_DATA_LOADED] (state: any, value) {
    state.isLoaded = value;
  },
  [constants.SET_IS_LOADING_MEMBERS_LIST] (state: any, value) {
    state.loadingMembersList = value;
  },
}
