import Vue from 'vue';
import { defaultDashboardWidgets } from '@/bundles/Settings/helpers/defaultLayout';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { GET_LAYOUT_SETTINGS, INITIALIZE_PAGE_LAYOUT, UPDATE_PAGE_LAYOUT } from '@/store/modules/settings/settings-types';
import { IUserLayoutWidget } from '@/bundles/Settings/interfaces/IUserLayoutWidget';
import { IUserLayout } from '@/bundles/Settings/interfaces/IUserLayout';

const DashboardLayoutMixin = Vue.extend({
  data: (): { isLayoutSettingsLoading: boolean; isSaving: boolean; } => ({
    isLayoutSettingsLoading: true,
    isSaving: false,
  }),

  computed: {
    ...mapGetters('Settings', {
      getLayoutSettingsByPage: 'getLayoutSettingsByPage',
    }),
    dashboardLayoutSettings (): IUserLayout {
      return this.getLayoutSettingsByPage('dashboard');
    },
    dashboardWidgets (): Array<IUserLayoutWidget> {
      return this.dashboardLayoutSettings?.widgets || [];
    },
  },

  mounted () {
    this.getDashboardLayout();
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    ...mapActions('Settings', {
      getLayoutSettings: GET_LAYOUT_SETTINGS,
      initializePageLayout: INITIALIZE_PAGE_LAYOUT,
      updatePageLayout: UPDATE_PAGE_LAYOUT,
    }),
    async getDashboardLayout () {
      try {
        if (this.dashboardLayoutSettings) {
          return;
        }
        this.isLayoutSettingsLoading = true;

        await this.getLayoutSettings({ page: 'dashboard' });
      } catch (error) {
        if (error.message.includes('No settings for')) {
          await this.initializePageLayoutHandler();
          return;
        }
        this.addNotification({ ...error });
      } finally {
        this.isLayoutSettingsLoading = false;
      }
    },
    async initializePageLayoutHandler () {
      try {
        await this.initializePageLayout({ widgets: defaultDashboardWidgets, page: 'dashboard' });
      } catch (error) {
        this.addNotification({ ...error });
      }
    },
    async saveOrder (items) {
      try {
        this.isSaving = true;
        await this.updatePageLayout({
          widgets: items.map((widget, index) => ({ ...widget, order: index })),
          page: 'dashboard'
        });
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.isSaving = false;
      }
    },
  }
});

export { DashboardLayoutMixin };
