import Member from '@/bundles/Members/models/Member';
import Account from '@/bundles/Account/models/Account';

export default class Team {
  id!: number;
  name!: string;
  members!: Member[];
  is_active!: boolean;
  account!: Account;
  created!: string;
  updated!: string;
}
