import actions from './actions';
import mutations from './mutations';
import { IBroker } from '@/bundles/Brokers/interfaces/IBroker';
import { IMarket } from '@/bundles/Markets/interfaces/IMarket';

interface IInitialState {
  isLoaded: boolean;
  loadingMembersList: boolean;
  markets: Array<{ name: string }>;
  subMarkets: Array<{ name: string }>;
  businessParks: Array<{ name: string }>;
  teams: Array<any>; // TODO: add type
  memberList: Array<any>; // TODO: add type
}

const state = (): IInitialState => ({
  isLoaded: false,
  loadingMembersList: false,
  markets: [],
  subMarkets: [],
  businessParks: [],
  teams: [],
  memberList: [],
});

export default {
  namespaced: true,
  state,
  getters: {},
  actions,
  mutations,
}
