import Enterprise from '@/bundles/Account/models/Enterprise';
import { Nullable } from '@/utils/types';
import { IReportSizeRangeItem } from '@/bundles/Reports/interfaces';

export default class Account {
  id!: number;
  name!: string;
  legal_name!: string;
  enterprise!: Enterprise;
  is_active!: boolean;
  is_brokerage!: Nullable<boolean>;
  is_integrated_services!: Nullable<boolean>;
  zip?: string;
  fax?: string;
  phone?: string;
  country?: string;
  state?: string;
  address1?: string;
  address2?: string;
  domain?: string;
  city?: string;
  account_type?: any;
  logo?: string;
  email_logo?: string;
  alt_text?: string;
  square_logo?: string;
  watermark?: string;
  property_placeholder?: string;
  public_logo?: string;
  contact_placeholder?: string;

  primary_color?: string;
  secondary_color?: string;
  marker_color?: string;
  custom_marker?: string;
}
