import { sprinklerTypes } from '@/bundles/Properties/helpers';
import { ContactRolesEnum } from '@/bundles/Contact/enums/contactRoles';
import { PrivacyType } from '@/bundles/App/types';
import { getAvailabilityStatusItems } from '@/bundles/Availabilities/helpers';
import i18n from '@/bundles/Localization';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';
import { getPropertyCenterTypeTextValues } from '@/bundles/Property/enums/PropertyCenterTypeEnum';
import { railLines } from '@/bundles/Filters/consts/railLines';

export const availableFilters = {
  property_type: 'select',
  property_size: 'range',
  available_size: 'range',
  divisible_size: 'range',
  transaction_type: 'autocomplete',
  status: 'select',
  construction_status: 'autocomplete',
  office_size: 'range',
  land_size: 'range',
  sales_price: 'range',
  lease_rate: 'range',
  sprinklers: 'autocomplete',
  clear_height: 'string',
  dock_doors: 'string',
  ramp_grade: 'string',
};

export const expandedFiltersName = {
  property_type: 'Property Type',
  property_size: 'Property Size',
  available_size: 'Available Size',
  divisible_size: 'Divisible Size',
  transaction_type: 'Transaction Type',
  status: 'Status',
  construction_status: 'Construction Status',
  office_size: 'Office Size',
  land_size: 'Land Size',
  sales_price: 'Price',
  lease_rate: 'Lease Rate',
  sprinklers: 'Sprinkler Type',
  clear_height: 'Clear Height',
  dock_doors: 'Dock Doors',
  ramp_grade: 'Ramp Grade',
};

export const autocompleteItems = {
  transaction_type: [
    { text: 'For Lease', value: 'lease' },
    { text: 'For Sale', value: 'sale' },
  ],
  construction_status: [
    { text: 'Proposed', value: 'Proposed' },
    { text: 'Under Construction', value: 'Under Construction' },
    { text: 'Existing', value: 'Existing' },
  ],
  sprinklers: sprinklerTypes,
};

export const selectItems = {
  property_type: [],
  status: [
    { text: 'Active (Internal)', value: 'Active (Internal)' },
    ...getAvailabilityStatusItems().map(item => ({
      text: item.text,
      value: item.value.toLowerCase()
    }))
  ],
};

export const getGlobalFilters = () => ({
  group: 'availabilities',
  model: false,
  isActive: false,
  icon: 'mdi-map-marker-radius',
  title: 'Availabilities',
  children: [
    {
      icon: 'mdi-adjust',
      title: 'In House',
      name: 'availability_in_house',
      type: 'inhouse'
    },
    {
      icon: 'mdi-adjust',
      title: 'Agency Type',
      name: 'availability_agency_type',
      type: 'agency_type',
      items: [
        { text: 'Exclusive', value: 'Exclusive' },
        { text: 'Non-Exclusive', value: 'Non-Exclusive' },
        { text: 'Owner Listed', value: 'Owner Listed' },
        { text: 'Pocket', value: 'Pocket' },
      ]
    },
    {
      type: 'custom_transaction_filter',
      children: [
        {
          title: 'Transaction Type',
          name: 'availability_transaction_type',
        },
        {
          title: 'Investment Sale',
          name: 'availability_investment'
        },
        {
          title: 'SubLease',
          name: 'availability_sublease'
        },
        {
          title: 'Transaction Status',
          name: 'availability_transaction_status'
        }
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Type',
      items: [],
      name: 'property_type',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Use Type(s)',
      name: 'availability_use_type',
      type: 'use_type_filter',
    },
    {
      type: 'custom_price_filter',
      children: [
        {
          title: 'Lease Rate',
          name: 'availability_lease_rate',
          type: 'range'
        },
        {
          title: 'Sale Price',
          name: 'availability_sales_price',
          type: 'range'
        },
        {
          name: 'availability_sales_price_unit',
          type: 'helper'
        },
        {
          title: 'Sale Price Per',
          name: 'availability_sales_price_sf',
          type: 'range'
        },
        {
          name: 'availability_sales_price_sf_unit',
          type: 'helper'
        },
      ]
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Available Space',
          name: 'availability_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'availability_land_size',
          type: 'range'
        },
        {
          name: 'availability_land_size_unit',
          type: 'helper'
        }
      ]
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.number_of_units),
      items: ['', ''],
      name: 'availability_building_units',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Date Listed',
      items: ['', ''],
      name: 'availability_date_listed',
      type: 'date_range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Divisible Size',
      items: ['', ''],
      name: 'availability_divisible_size',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Land Size POL',
      name: 'availability_land_pol',
      type: 'pol',
    },
    {
      icon: 'mdi-adjust',
      title: 'Office Size',
      items: ['', ''],
      name: 'availability_office_size',
      type: 'range',
    },
    {
      type: 'clear_height',
      title: 'Clear Height',
      children: [
        {
          title: 'Min Clear Height',
          type: 'building_dimension_range',
          name: 'availability_min_clear_height',
        },
        {
          title: 'Max Clear Height',
          type: 'building_dimension_range',
          name: 'availability_max_clear_height'
        },
      ]
    },
    {
      icon: 'mdi-adjust',
      title: '# Dock High Doors',
      items: ['', ''],
      name: 'availability_dock_door_count',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: '# Drive-In Doors',
      items: ['', ''],
      name: 'availability_ground_door_count',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Building Class',
      items: ['A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-'],
      name: 'property_building_class',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Year Built',
      items: ['', ''],
      name: 'availability_year_built',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.target_completion_year),
      items: ['', ''],
      name: 'property_target_completion_year',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Sublease Expiration Date',
      name: 'sublease_expiration_date',
      type: 'sublease_expiration_date',
    },
    {
      type: 'divider'
    },
    {
      title: 'Permissioned for',
      items: [
        { text: 'All users', _key: PrivacyType.MY_OFFICE },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      hideInactive: true,
      name: 'availability_permission_view',
      type: 'create_updated_select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Availability Status',
      name: 'availability_status',
      type: 'select',
      items: getAvailabilityStatusItems().map(item => ({
        text: item.text,
        value: item.value.toLowerCase()
      }))
    },
    {
      icon: 'mdi-adjust',
      title: 'Verification Status',
      name: 'verification_status',
      type: 'select',
      items: [
        { text: 'Verified', value: 'verified' },
        { text: 'Pending', value: 'pending' },
        { text: 'Expired', value: 'expired' },
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Pocket Listing',
      name: 'availability_pocket',
      type: 'pocket',
      items: []
    },
    {
      icon: 'mdi-adjust',
      title: 'Active To Web',
      name: 'availability_published_to_web',
      type: 'all_exclude_only',
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'availability_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'availability_view',
      type: 'create_updated_select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Property Filters'
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.retail_center_type),
      items: getPropertyCenterTypeTextValues(),
      name: 'property_center_type',
      type: 'select',
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Property Size',
          name: 'property_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'property_land_size',
          type: 'range'
        },
        {
          name: 'property_land_size_unit',
          type: 'helper'
        }
      ],
      meta: {
        prefix: 'property',
        title: 'Property Size'
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Sprinkler Type',
      items: sprinklerTypes,
      name: 'property_sprinkler_type',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Construction Status',
      items: [
        { text: 'Proposed', value: 'proposed' },
        { text: 'Under Construction', value: 'under construction' },
        { text: 'Existing', value: 'existing' },
      ],
      name: 'property_construction_status',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Loading and Doors'
    },
    {
      icon: 'mdi-adjust',
      title: 'Dock Orientation',
      name: 'availability_dock_orientation',
      type: 'one_select',
      items: [
        { text: 'Cross Dock', value: 'cross dock' },
        { text: 'Rear Load', value: 'rear load' },
        { text: 'Front Load', value: 'front load' },
        { text: 'Side Load', value: 'side load' },
        { text: 'Tri Load', value: 'tri load' },
      ],
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Rail'
    },
    {
      icon: 'mdi-adjust',
      title: 'Rail Status',
      items: ['Active', 'In Place', 'Available', 'Unavailable'],
      name: 'property_rail_status',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Rail Line',
      items: railLines,
      name: 'property_rail_line',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Market & Location'
    },
    {
      icon: 'mdi-adjust',
      title: 'City',
      name: 'property_location_city',
      type: 'city_combobox',
      class: 'mt-0 pa-0'
    },
    {
      icon: 'mdi-adjust',
      title: 'State',
      name: 'property_location_state',
      type: 'states_combobox',
    },
    {
      icon: 'mdi-adjust',
      title: 'Zip / Postal Code',
      name: 'availability_zip_code',
      type: 'postal_code_combobox',
    },
    {
      icon: null,
      title: null,
      items: [],
      name: 'property_market',
      type: 'dynamic_market',
      children: [
        {
          icon: 'mdi-adjust',
          title: `Property ${i18n.t('apn_number')}`,
          items: ['', ''],
          name: 'property_apn_number',
          type: 'textfield',
        },
      ]
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Company & Contact'
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Name',
      name: 'availability_company_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Name',
      name: 'availability_contact_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Company Role',
      items: [
        { text: 'Agent', value: ContactRolesEnum.agent },
        { text: 'Contractor', value: ContactRolesEnum.contractor },
        { text: 'Employee', value: ContactRolesEnum.employee },
      ],
      name: 'contact_company_role',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Availability Role',
      items: [
        { text: 'Architect', value: ContactRolesEnum.architect },
        { text: 'Developer', value: ContactRolesEnum.developer },
        { text: 'Landlord', value: ContactRolesEnum.landlord },
        { text: 'Listing Agent', value: ContactRolesEnum.listing_agent },
        { text: 'Listing Agent - Sublease', value: ContactRolesEnum['listing_agent-_-sublease'] },
        { text: 'Owner', value: ContactRolesEnum.owner },
        { text: 'Property Manager', value: ContactRolesEnum.property_manager },
        { text: 'Structural Engineer', value: ContactRolesEnum.structural_engineer },
        { text: 'True Owner', value: ContactRolesEnum.true_owner },
        { text: 'Previous Owner', value: ContactRolesEnum.previous_owner },
      ],
      name: 'availability_contact_role',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Broker',
      items: ['', ''],
      name: 'availability_broker',
      type: 'broker',
    },
    {
      title: 'Filtered Availabilities',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
