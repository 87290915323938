import { ContactSpatialSearchRecordTypeEnum } from '@/bundles/Filters/enums/ContactSpatialSearchRecordTypeEnum';

export const FILTERS_KEYS = ['dashboard', 'occupier', 'company', 'availability', 'property',
  'comparable', 'deal', 'contact', 'requirement', 'project', 'pipeline', 'savedSet',
  'member', 'team', 'request', 'eblast', 'pressrelease', 'map', 'marketing',
  'eblastList', 'profileAdmin', 'marketingTemplates', 'marketingCampaign', 'customRows'
];

export const FILTER_OBJECT_SHAPE = {
  filters: {},
  keyword: '',
  filterName: '',
  filterId: '',
  lastSelectedType: '',
};

export const getDefaultFiltersByType = (type: string): Record<string, any> => {
  if (type === 'contact') {
    return { contact_spatial_search_record_type: ContactSpatialSearchRecordTypeEnum.contact };
  }

  return {};
}

export const getDefaultFilterObjectShape = (type: string, isResetAll = false) => {
  if (isResetAll) {
    return Object.assign({}, FILTER_OBJECT_SHAPE);
  }

  return Object.assign({}, FILTER_OBJECT_SHAPE, { filters: getDefaultFiltersByType(type) });
}
