import { OccupancyTypeEnum } from '@/bundles/Occupiers/types';

export const getOccupancyTypeFilterItems = () => [
  { text: OccupancyTypeEnum.owner, value: 'ownerOccupied' },
  { text: OccupancyTypeEnum.lease, value: 'leased' },
  { text: OccupancyTypeEnum.pl3Operator, value: 'pl3Operator' },
  { text: OccupancyTypeEnum.pl3Occupant, value: 'pl3Occupant' },
];

export const getOccupancyTypeFilterLabel = (filterValue: string[]): string => {
  const labels = filterValue.map((value) => {
    const item = getOccupancyTypeFilterItems().find((item) => item.value === value);
    return item ? item.text : '';
  });

  return labels.join(', ');
}
