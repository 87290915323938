import { ContactRolesEnum } from '@/bundles/Contact/enums/contactRoles';
import { ContactSpatialSearchRecordTypeEnum } from '@/bundles/Filters/enums/ContactSpatialSearchRecordTypeEnum';
import { getSpecializationSelectItems } from '@/bundles/Contact/helpers/getSpecializationTypes';
import i18n from '@/bundles/Localization';
import { getUniqueSortedUseTypesByType } from '@/bundles/Property/helpers/getUseTypesByType';
import { getOccupancyTypeFilterItems } from '@/bundles/Occupier/helpers/getOccupancyTypeFilterItems';
import { getRailsStatusItems } from '@/bundles/Property/helpers';

export const availableFilters = {
  market: 'market',
  occupier_size: 'range',
  property_size: 'range',
  availability_size: 'range',
  occupier_roles: 'select',
  occupier_details: 'select',
  property_roles: 'select',
  occupancy_type: 'select',
  specialization: 'select',
  created_or_updated: 'autocomplete',
};

export const expandedFiltersName = {
  market: 'Market(s)',
  occupier_size: 'Occupier Size',
  property_size: 'Property Size',
  availability_size: 'Availability Size',
  occupier_roles: 'Occupier Role',
  occupier_details: 'Occupier Details',
  property_roles: 'Property Role',
  occupancy_type: 'Occupier Type',
  specialization: 'Agent Specialization',
  created_or_updated: 'Created/Updated by',
};

export const autocompleteItems = {
  created_or_updated: [
    { text: 'Me', value: 'member' },
    { text: 'Me or My Team(s)', value: 'team' },
  ],
};

export const selectItems = {
  occupier_roles: [
    { text: 'Influencer', value: 'influencer' },
    { text: 'Key Stakeholder', value: 'stakeholder' },
    { text: 'Listing Broker', value: 'broker' },
    { text: 'Local Manager', value: 'localManager' },
    { text: 'Regional Manager', value: 'regionalManager' },
    { text: 'Ultimate Decision Maker', value: 'ultimate' },
  ],
  occupier_details: [
    { text: 'Lease Expiring', value: 'leaseExpiring' },
    { text: 'Include Owner Occupied', value: 'ownerOccupied' },
    { text: 'Lease Expiring', value: 'leaseExpiring' },
  ],
  property_roles: [
    { text: 'Architect', value: ContactRolesEnum['architect'] },
    { text: 'Developer', value: ContactRolesEnum['developer'] },
    { text: 'Landlord', value: ContactRolesEnum['landlord'] },
    { text: 'Listing Agent', value: ContactRolesEnum['listing_agent'] },
    { text: 'Listing Agent - Sublease', value: ContactRolesEnum['listing_agent_-_sublease'] },
    { text: 'Owner', value: ContactRolesEnum['owner'] },
    { text: 'Property Manager', value: ContactRolesEnum['property_manager'] },
    { text: 'Structural Engineer', value: ContactRolesEnum['structural_engineer'] },
    { text: 'True Owner', value: ContactRolesEnum['true_owner'] },
  ],
  occupancy_type: [
    { text: 'Owner Occupied', value: 'ownerOccupied' },
    { text: 'Leased', value: 'leased' },
  ],
  specialization: [],
};

export const getGlobalFilters = () => ({
  group: 'contact',
  model: false,
  isActive: false,
  icon: 'mdi-contacts',
  title: 'Contacts',
  children: [
    {
      icon: 'mdi-adjust',
      title: 'Contact Type',
      name: 'company_my_office_enterprise',
      type: 'my_office_enterprise'
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Name',
      name: 'contact_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Title',
      name: 'contact_title',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Specialization',
      items: getSpecializationSelectItems(),
      name: 'contact_specialization',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Tag(s)',
      name: 'contact_tags',
      type: 'tags_multiply_select'
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Email',
      items: [
        { text: 'All Contacts', value: 'all' },
        { text: 'Only contacts with an email', value: 'yes' },
        { text: 'Only contact without an email', value: 'no' },
      ],
      name: 'contact_has_email',
      type: 'one_select'
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Tracked',
      name: 'contact_tracked',
      type: 'simple_switch'
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Office Address'
    },
    {
      icon: 'mdi-adjust',
      title: 'City',
      name: 'contact_office_city',
      type: 'textfield',
      cols: 6,
    },
    {
      icon: 'mdi-adjust',
      title: 'State',
      name: 'contact_office_state',
      type: 'textfield',
      cols: 3,
    },
    {
      icon: 'mdi-adjust',
      title: 'Postal',
      name: 'contact_office_postal',
      type: 'textfield',
      cols: 3,
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'contact_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'contact_view',
      type: 'create_updated_select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Polygon Search'
    },
    {
      icon: 'mdi-adjust',
      title: 'Search by',
      items: [
        { text: 'Contact Location', value: ContactSpatialSearchRecordTypeEnum.contact },
        { text: 'Property Location', value: ContactSpatialSearchRecordTypeEnum.property },
      ],
      name: 'contact_spatial_search_record_type',
      type: 'one_select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Company Filters'
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Name',
      name: 'company_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Annual Revenue',
      items: ['', ''],
      format: 'currency',
      name: 'company_annual_revenue',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Brokerage Company Filter',
      name: 'company_is_brokerage',
      type: 'brokerage_switch',
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Role',
      items: [
        { text: 'Agent', value: ContactRolesEnum.agent },
        { text: 'Contractor', value: ContactRolesEnum.contractor },
        { text: 'Employee', value: ContactRolesEnum.employee },
      ],
      name: 'contact_company_role',
      type: 'select',
      class: 'mt-4'
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Occupier Filters'
    },
    {
      icon: 'mdi-adjust',
      title: 'Occupier Contact Role',
      items: [
        { text: 'Influencer', value: 'Influencer' },
        { text: 'Key Stakeholder', value: 'Key Stake Holder' },
        { text: 'Listing Broker', value: 'Listing Broker' },
        { text: 'Local Manager', value: 'Local Manager' },
        { text: 'Procuring Broker', value: 'Procuring Broker' },
        { text: 'Regional Manager', value: 'Regional Manager' },
        { text: 'Ultimate Decision Maker', value: 'Ultimate Decision Maker' },
        {
          text: 'Vendor-Material Handling',
          value: 'Vendor-Material Handling',
        },
      ],
      name: 'occupier_contact_role',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Occupier size',
      items: ['', ''],
      name: 'occupier_size',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Occupier occupancy type',
      items: getOccupancyTypeFilterItems(),
      name: 'occupier_occupancy_type',
      type: 'one_select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Occupier Lease Expiration Date',
      items: ['', ''],
      name: 'occupier_lease_expiration_date',
      type: 'date_range',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Property Filters'
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Property Size',
          name: 'property_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'property_land_size',
          type: 'range'
        },
        {
          name: 'property_land_size_unit',
          type: 'helper'
        }
      ],
      meta: {
        title: 'Property Size',
        prefix: 'property'
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Contact Role',
      items: selectItems['property_roles'],
      name: 'property_contact_role',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Type',
      items: [],
      name: 'property_type',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Use',
      items: [],
      name: 'property_use',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Use Type',
      name: 'property_use_type',
      type: 'use_type_filter',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Rail Status',
      items: getRailsStatusItems(),
      name: 'property_rail_status',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Construction Status',
      items: [
        { text: 'Proposed', value: 'proposed' },
        { text: 'Under Construction', value: 'under construction' },
        { text: 'Existing', value: 'existing' },
      ],
      name: 'property_construction_status',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Market & Location'
    },
    {
      icon: 'mdi-adjust',
      title: 'City',
      name: 'property_location_city',
      type: 'city_combobox',
      class: 'mt-0 pa-0'
    },
    {
      icon: 'mdi-adjust',
      title: 'State',
      name: 'property_location_state',
      type: 'states_combobox',
    },
    {
      icon: 'mdi-adjust',
      title: 'Zip / Postal Code',
      name: 'property_location_postal_cd',
      type: 'postal_code_combobox',
    },
    {
      icon: null,
      title: null,
      items: [],
      name: 'property_market',
      type: 'dynamic_market',
      children: [
        {
          icon: 'mdi-adjust',
          title: `Property ${i18n.t('apn_number')}`,
          items: ['', ''],
          name: 'property_apn_number',
          type: 'textfield',
        },
      ]
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Availability Filters'
    },
    {
      icon: 'mdi-adjust',
      title: 'Availability Contact Role',
      items: [
        { text: 'Architect', value: ContactRolesEnum.architect },
        { text: 'Developer', value: ContactRolesEnum.developer },
        { text: 'Landlord', value: ContactRolesEnum.landlord },
        { text: 'Listing Agent', value: ContactRolesEnum.listing_agent },
        {
          text: 'Listing Agent - Sublease',
          value: ContactRolesEnum['listing_agent-_-sublease'],
        },
        { text: 'Owner', value: ContactRolesEnum.owner },
        { text: 'Property Manager', value: ContactRolesEnum.property_manager },
        { text: 'Structural Engineer', value: ContactRolesEnum.structural_engineer },
        { text: 'True Owner', value: ContactRolesEnum.true_owner },
        { text: 'Previous Owner', value: ContactRolesEnum.previous_owner },
      ],
      name: 'availability_contact_role',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Availability Status',
      name: 'availability_status',
      type: 'select',
      items: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Leased', value: 'leased' },
        { text: 'Pending', value: 'pending' },
        { text: 'Sold', value: 'sold' },
      ]
    },
    {
      type: 'custom_transaction_filter',
      children: [
        {
          title: 'Transaction Type',
          name: 'contact_transaction_type',
        },
        {
          title: 'Investment Sale',
          name: 'contact_investment'
        },
        {
          title: 'SubLease',
          name: 'contact_sublease'
        }
      ]
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Available Space',
          name: 'availability_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'availability_land_size',
          type: 'range'
        },
        {
          name: 'availability_land_size_unit',
          type: 'helper'
        }
      ],
      meta: {
        prefix: 'available',
        title: 'Available Space'
      }
    },
    {
      type: 'custom_price_filter',
      children: [
        {
          title: 'Lease Rate',
          name: 'availability_lease_rate',
          type: 'range'
        },
        {
          title: 'Sale Price',
          name: 'availability_sales_price',
          type: 'range'
        },
        {
          name: 'availability_sales_price_unit',
          type: 'helper'
        },
        {
          title: 'Sale Price Per',
          name: 'availability_sales_price_sf',
          type: 'range'
        },
        {
          name: 'availability_sales_price_sf_unit',
          type: 'helper'
        },
      ]
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Pipeline Filters'
    },
    {
      icon: 'mdi-adjust',
      title: 'Pipeline Stage',
      items: [
        { text: 'Appointment Pending', value: 1 },
        { text: 'Appointment Scheduled', value: 2 },
        { text: 'Presentation Scheduled', value: 3 },
        { text: 'Exclusive Pending', value: 4 },
        { text: 'Exclusive Secured', value: 5 },
        { text: 'RFP/LOI', value: 6 },
        { text: 'Contact / Lease Working', value: 7 },
        { text: 'On Hold', value: 8 },
        { text: 'Closed - Won', value: 9 },
        { text: 'Closed - Construction Pending', value: 10 },
        { text: 'Closed - Lost', value: 11 },
      ],
      name: 'pipeline_stage',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Pipeline Transaction Category',
      items: [
        { text: 'Tenant Representation', value: 'Tenant Representation' },
        { text: 'Buyer Representation', value: 'Buyer Representation' },
        { text: 'Landlord Representation', value: 'Landlord Representation' },
        { text: 'Seller Representation', value: 'Seller Representation' },
        { text: 'Corporate Services', value: 'Corporate Services' },
        { text: 'Consulting', value: 'Consulting' },
      ],
      name: 'pipeline_category',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Pipeline Contact Role',
      name: 'pipeline_contact_role',
      items: [
        { text: 'Landlord Contact', value: 'Landlord Contact' },
        { text: 'Landlord Broker', value: 'Landlord Broker' },
        { text: 'Local Manager', value: 'Local Manager' },
        { text: 'Regional Manager', value: 'Regional Manager' },
        { text: 'Seller Broker', value: 'Seller Broker' },
        { text: 'Tenant Broker', value: 'Tenant Broker' },
        { text: 'Ultimate Decision Maker', value: 'Ultimate Decision Maker' },
      ],
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Deal Filters'
    },
    {
      icon: 'mdi-adjust',
      title: 'Deal Contact Role',
      name: 'contact_deal_role',
      items: [
        { text: 'Tenant Contact', value: 'tenant' },
        { text: 'Buyer Contact', value: 'buyer' },
        { text: 'Landlord Contact', value: 'landlord' },
        { text: 'Seller Contact', value: 'seller' },
        { text: 'Listing Contact', value: 'listing_agent' },
        { text: 'Primary Client Contact', value: 'primary_client' },
        { text: 'Billing Contact', value: 'billing' },
      ],
      type: 'select',
    },
    {
      title: 'Filtered Contacts',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
