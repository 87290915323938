import { ContactRolesEnum } from '@/bundles/Contact/enums/contactRoles';
import i18n from '@/bundles/Localization';
import { RentScheduleTypeEnum } from '@/bundles/Comparables/types';
import { getUniqueSortedUseTypesByType } from '@/bundles/Property/helpers/getUseTypesByType';
import { getOccupancyTypeFilterItems } from '@/bundles/Occupier/helpers/getOccupancyTypeFilterItems';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';
import { getRailsStatusItems } from '@/bundles/Property/helpers';

export const availableFilters = {
  occupier_size: 'range',
  property_size: 'range',
  occupancy_type: 'select',
  company: 'string',
  includeChildren: 'boolean',
  locationType: 'autocomplete',
  expiringIn: 'autocomplete',
  showVacated: 'boolean',
  contactRoles: 'select',
};

export const expandedFiltersName = {
  occupier_size: 'Occupier Size',
  property_size: 'Property Size',
  company: 'Company',
  contactRoles: 'Contact Role(s)',
  expiringIn: 'Expiring in',
  includeChildren: 'Include Child Companies',
  locationType: 'Location Type',
  occupancy_type: 'Occupancy Type',
  showVacated: 'Include Vacated Occupiers',
};

export const autocompleteItems = {
  expiringIn: [
    { text: '3 months', value: '3 months' },
    { text: '6 months', value: '6 months' },
    { text: '9 months', value: '9 months' },
    { text: '12 months', value: '12 months' },
    { text: '18 months', value: '18 months' },
    { text: '21 months', value: '21 months' },
    { text: '24 months', value: '24 months' },
  ],
  locationType: [],
};

export const selectItems = {
  contactRoles: [
    { text: 'Influencer', value: 'influencer' },
    { text: 'Key Stakeholder', value: 'stakeholder' },
    { text: 'Listing Broker', value: 'broker' },
    { text: 'Local Manager', value: 'localManager' },
    { text: 'Regional Manager', value: 'regionalManager' },
    { text: 'Ultimate Decision Maker', value: 'ultimate' },
  ],
  occupancy_type: [
    { text: 'Owner Occupied', value: 'ownerOccupied' },
    { text: 'Leased', value: 'leased' },
  ],
};

export const getGlobalFilters = () => ({
  group: 'occupier',
  model: false,
  isActive: false,
  icon: 'mdi-office-building-marker',
  title: 'Occupiers',
  children: [
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.occupier_use_types),
      items: getUniqueSortedUseTypesByType(),
      name: 'occupier_use_type',
      type: 'use_type_filter',
    },
    {
      icon: 'mdi-adjust',
      title: 'Vacated Occupiers',
      name: 'occupier_with_vacated',
      type: 'all_exclude_only',
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Available Space',
          name: 'occupier_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'occupier_land_size',
          type: 'range'
        },
        {
          name: 'occupier_land_size_unit',
          type: 'helper'
        }
      ]
    },
    {
      icon: 'mdi-adjust',
      title: 'Occupier Market',
      name: 'occupier_market',
      type: 'market',
    },
    {
      icon: 'mdi-adjust',
      title: 'Occupancy Type',
      items: getOccupancyTypeFilterItems(),
      name: 'occupier_occupancy_type',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: i18n.t(LocalizationEnum.schedule_type),
      items: [
        { text: RentScheduleTypeEnum.new, value: RentScheduleTypeEnum.new },
        { text: RentScheduleTypeEnum.renewal, value: RentScheduleTypeEnum.renewal },
        { text: RentScheduleTypeEnum.modification, value: RentScheduleTypeEnum.modification },
        { text: RentScheduleTypeEnum.expansion, value: RentScheduleTypeEnum.expansion },
        { text: RentScheduleTypeEnum.extension, value: RentScheduleTypeEnum.extension },
      ],
      name: 'occupier_schedule_type',
      type: 'one_select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Sublease',
      name: 'occupier_sublease',
      type: 'simple_switch',
    },
    {
      icon: 'mdi-adjust',
      title: 'Lease Expiration Date',
      items: ['', ''],
      name: 'occupier_lease_expiration_date',
      type: 'date_range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Lease Expiration in',
      items: [
        { text: '3 months', value: '3 months' },
        { text: '6 months', value: '6 months' },
        { text: '9 months', value: '9 months' },
        { text: '12 months', value: '12 months' },
        { text: '18 months', value: '18 months' },
        { text: '21 months', value: '21 months' },
        { text: '24 months', value: '24 months' },
      ],
      name: 'occupier_lease_expiration',
      type: 'one_select',
    },
    {
      icon: 'mdi-list',
      title: 'Filter by Occupier Set',
      name: 'occupier_set',
      filterName: 'occupier',
      type: 'set',
    },
    {
      icon: 'mdi-vector-polygon',
      title: 'Apply spatial search for occupiers',
      name: 'occupier_polygon_switch',
      filterName: 'occupier',
      type: 'simple_switch',
    },
    {
      icon: 'mdi-adjust',
      title: 'Original Possession Date',
      items: ['', ''],
      name: 'occupier_original_possession_date',
      type: 'date_range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Lease Commencement',
      items: ['', ''],
      name: 'occupier_lease_commencement',
      type: 'date_range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Lease Execution',
      items: ['', ''],
      name: 'occupier_lease_execution',
      type: 'date_range',
    },
    {
      title: 'Lease Term',
      name: 'occupier_lease_term',
      type: 'range'
    },
    {
      title: 'Free Rent',
      name: 'occupier_free_rent',
      type: 'range'
    },
    {
      title: 'Starting Rent',
      name: 'occupier_starting_rent',
      type: 'range'
    },
    {
      title: 'Average Rent',
      name: 'occupier_average_rent',
      type: 'range'
    },
    {
      title: 'Effective Rent',
      name: 'occupier_effective_rent',
      type: 'range'
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'occupier_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'occupier_view',
      type: 'create_updated_select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Contact Filters'
    },
    {
      icon: 'mdi-adjust',
      title: 'Contact Role',
      items: [
        { text: 'Influencer', value: ContactRolesEnum.influencer },
        { text: 'Key Stakeholder', value: ContactRolesEnum.key_stake_holder },
        { text: 'Listing Broker', value: ContactRolesEnum.listing_broker },
        { text: 'Local Manager', value: ContactRolesEnum.local_manager },
        { text: 'Procuring Broker', value: ContactRolesEnum.procuring_broker },
        { text: 'Regional Manager', value: ContactRolesEnum.regional_manager },
        { text: 'Ultimate Decision Maker', value: ContactRolesEnum.ultimate_decision_maker },
        {
          text: 'Vendor-Material Handling',
          value: 'Vendor-Material Handling',
        },
      ],
      name: 'occupier_contact_role',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      type: 'title',
      title: 'Property Filters'
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Available Space',
          name: 'availability_size',
          type: 'range'
        },
        {
          title: 'Land Size',
          name: 'availability_land_size',
          type: 'range'
        },
        {
          name: 'availability_land_size_unit',
          type: 'helper'
        }
      ],
      meta: {
        prefix: 'property',
        title: 'Property Size'
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Type',
      items: [],
      name: 'property_type',
      type: 'select',
      options: {
        icons: [
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
          'mdi-hospital-building',
        ]
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Use',
      items: [],
      name: 'property_use',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Use Type',
      name: 'property_use_type',
      type: 'use_type_filter',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Rail Status',
      items: getRailsStatusItems(),
      name: 'property_rail_status',
      type: 'select',
    },
    {
      title: 'Filtered Occupiers',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
