import mutations from './mutations'

const initialState = {
  company: 'table',
  contact: 'table',
  occupier: 'table',
  property: 'table',
  availability: 'propertyType',
  comparable: 'table',
  project: 'table',
  pipeline: 'table',
  deals: 'table',
  requirement: 'table',
  members: 'table',
}

export default {
  namespaced: true,
  state: initialState,
  mutations,
}
