import Team from '@/bundles/Teams/models/Team';
import MemberToMapper from '@/bundles/Members/mappers/MemberToMapper';
import AccountToMapper from '@/bundles/Account/mappers/AccountToMapper';
import Account from '@/bundles/Account/models/Account';

export default class TeamToMapper {
  mapToModel (data: any): Team {
    const team = new Team();
    team.id = data.id;
    team.name = data.name;
    team.members = data.members ? data.members.map((member: any) => new MemberToMapper().mapToModel(member)) : [];
    team.is_active = data.is_active;
    team.account = data.account ? new AccountToMapper().mapToModel(data.account) : new Account();
    team.created = data.created;
    team.updated = data.updated;
    return team;
  }
}
