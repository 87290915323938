import { getUniqueSortedUseTypesByType } from '@/bundles/Property/helpers/getUseTypesByType';
import { getIndustryTypesbyGroup, getIndustryTypes } from '@/bundles/Companies/types/companyIndustryType';
import { getRailsStatusItems } from '@/bundles/Property/helpers';

export const availableFilters = {
  occupier_size: 'range',
  property_size: 'range',
  is_brokerage: 'boolean',
  annual_revenue: 'range',
};

export const expandedFiltersName = {
  occupier_size: 'Occupier Size',
  property_size: 'Property Size',
  is_brokerage: 'Brokerage company',
  annual_revenue: 'Annual Revenue',
};

export const getGlobalFilters = () => ({
  group: 'company',
  model: false,
  isActive: false,
  icon: 'mdi-city',
  title: 'Companies',
  children: [
    {
      icon: 'mdi-adjust',
      title: 'Company Name',
      name: 'company_ids',
      type: 'companies_multiply_select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Type',
      name: 'company_my_office_enterprise',
      type: 'my_office_enterprise'
    },
    {
      icon: 'mdi-adjust',
      title: 'Company Name',
      name: 'company_name',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'City',
      name: 'company_city',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'County',
      name: 'company_county',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'State',
      name: 'company_state',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Postal',
      name: 'company_postal',
      type: 'textfield',
    },
    {
      icon: 'mdi-adjust',
      title: 'Industry Group',
      items: getIndustryTypes(),
      name: 'company_industry_group',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Industry',
      items: getIndustryTypesbyGroup(),
      name: 'company_industry',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Annual Revenue',
      items: ['', ''],
      format: 'currency',
      name: 'company_annual_revenue',
      type: 'range',
    },
    {
      icon: 'mdi-adjust',
      title: 'Occupier Size',
      items: ['', ''],
      name: 'occupier_size',
      type: 'range',
    },
    {
      type: 'divider'
    },
    {
      icon: 'mdi-adjust',
      title: 'Include parent companies',
      name: 'company_show_parent',
      type: 'simple_switch',
    },
    {
      icon: 'mdi-adjust',
      title: 'Include child companies',
      name: 'company_show_child',
      type: 'simple_switch',
    },
    {
      icon: 'mdi-adjust',
      title: 'Brokerage Company Filter',
      name: 'company_is_brokerage',
      type: 'brokerage_switch',
    },
    {
      type: 'title',
      title: 'Property Filters'
    },
    {
      type: 'custom_size_filter',
      children: [
        {
          title: 'Property Size',
          name: 'property_size',
          type: 'range'
        },
        {
          title: 'Property Land Size',
          name: 'property_land_size',
          type: 'range'
        },
        {
          name: 'property_land_size_unit',
          type: 'helper'
        }
      ],
      meta: {
        title: 'Property Size',
        landTitle: 'Property Land Size',
        prefix: 'property'
      }
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Use',
      items: [],
      name: 'property_use',
      type: 'select',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Use Type',
      name: 'property_use_type',
      type: 'use_type_filter',
    },
    {
      icon: 'mdi-adjust',
      title: 'Property Rail Status',
      items: getRailsStatusItems(),
      name: 'property_rail_status',
      type: 'select',
    },
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'company_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'company_view',
      type: 'create_updated_select',
    },
    {
      title: 'Filtered Companies',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ],
});
