import TeamsService from '@/bundles/Teams/services/TeamsService';
import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import TeamToMapper from '@/bundles/Teams/mappers/TeamToMapper';

function teamsServiceFactory () {
  return new TeamsService({
    transport: httpV2,
    errorMapper: new ErrorMapper(),
    teamMapper: new TeamToMapper(),
  });
}

export const teamsService = teamsServiceFactory();
