































































































import { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import { IHeader } from '@/bundles/BaseTable/interfaces';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';

import OpenBalancesInvoiceTable from './OpenBalancesInvoiceTable.vue';

interface IBrokerProductionTableData {
  headers: IHeader[];
  expanded: any[];
}

interface IBrokerProductionTableFooterTotals {
  count: number;
  expected: number;
  inHouseExpected: number;
  paid: number;
  brokerGross: number;
  remaining: number;
  brokerGrossRemaining: number;
}

const headers = [
  { text: '#', value: '#', sortable: false },
  {
    text: 'Name',
    value: 'name',
    sortable: true,
    class: '',
    icon: 'string',
  },
  {
    text: 'Transaction Date',
    value: 'transactionDate',
    sortable: true,
    class: '',
    icon: 'string',
  },
  {
    text: 'Total Commission',
    value: 'expected',
    sortable: true,
    class: '',
    icon: 'string',
  },
  {
    text: 'Total Paid',
    value: 'payments',
    sortable: true,
    class: '',
    icon: 'string',
  },
  {
    text: 'Total Outstanding',
    value: 'remaining',
    sortable: true,
    icon: 'date',
  },
  {
    text: 'Total In-House Commission',
    value: 'inHouseExpected',
    sortable: true,
    class: '',
    icon: 'string',
  },
  {
    text: 'Broker Gross',
    value: 'brokerGross',
    sortable: true,
    class: '',
    icon: 'string',
  },
  {
    text: 'Broker Gross Outstanding',
    value: 'brokerGrossRemaining',
    sortable: true,
    icon: 'date',
  }
];

export default mixins(AccountLayoutMixin).extend({
  name: 'OpenBalancesTable',

  components: {
    OpenBalancesInvoiceTable
  },

  props: {
    value: {
      type: Object as PropType<{ sortBy: [string]; sortDesc: [boolean]; }>,
      required: true
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    filterLoading: Boolean
  },

  data: (): IBrokerProductionTableData => ({
    headers,
    expanded: [],
  }),

  computed: {
    sortBy: {
      get (): [string] {
        return this.value.sortBy;
      },
      set (value: [string]): void {
        this.$emit('input', {
          ...this.value,
          sortBy: value
        })
      },
    },
    sortDesc: {
      get (): [boolean] {
        return this.value.sortDesc;
      },
      set (value: [boolean]): void {
        this.$emit('input', {
          ...this.value,
          sortDesc: value
        })
      },
    },
    totals (): IBrokerProductionTableFooterTotals {
      const returnTotals = {
        count: this.items.length,
        expected: 0,
        inHouseExpected: 0,
        paid: 0,
        brokerGross: 0,
        remaining: 0,
        brokerGrossRemaining: 0
      }
      for (const item of this.items) {
        returnTotals.expected += item.expected;
        returnTotals.inHouseExpected += item.inHouseExpected;
        returnTotals.paid += item.payments;
        returnTotals.brokerGross += item.brokerGross;
        returnTotals.remaining += item.remaining;
        returnTotals.brokerGrossRemaining += item.brokerGrossRemaining;
      }
      return returnTotals
    }
  }
});
