import { pipelineStages } from '@/bundles/Pipelines/types/stageEnum';
import { ITextValue } from '@/types/common';
import { getSelectItems } from '@/bundles/Pipelines/helpers';
import store from '@/store/store';
import { arrToSnakeCase, ContactRolesEnum, getContactRoles } from '@/bundles/Contact/enums/contactRoles';
import getters from '@/store/modules/admin/getters';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';
import i18n from '@/bundles/Localization';

export const availableFilters = {
  teams: 'teams',
  category: 'select',
  stage: 'select',
  closedate: 'select',
};

export const expandedFiltersName = {
  category: 'Transaction Category',
  stage: 'Pipeline Stage',
  closedate: 'Close Date',
  allPipelines: 'Show all pipelines',
  teams: 'My Team(s)',
};

export const autocompleteItems = {
  category: [
    { text: 'Tenant Representation', value: 'Tenant Representation' },
    { text: 'Buyer Representation', value: 'Buyer Representation' },
    { text: 'Landlord Representation', value: 'Landlord Representation' },
    { text: 'Seller Representation', value: 'Seller Representation' },
    { text: 'Corporate Services', value: 'Corporate Services' },
    { text: 'Consulting', value: 'Consulting' },
  ],
};

export const selectItems = {
  category: getSelectItems().transactionCategories,
  stage: [
    { text: 'Appointment Pending', value: 'Appointment Pending' },
    { text: 'Appointment Scheduled', value: 'Appointment Scheduled' },
    { text: 'Presentation Scheduled', value: 'Presentation Scheduled' },
    { text: 'Exclusive Pending', value: 'Exclusive Pending' },
    { text: 'Exclusive Secured', value: 'Exclusive Secured' },
    { text: 'RFP/LOI', value: 'RFP/LOI' },
    { text: 'Contact / Lease Working', value: 'Contact/Lease Working' },
    { text: 'On Hold', value: 'On Hold' },
    { text: 'Closed - Won', value: 'Closed/Won' },
    {
      text: 'Closed - Construction Pending',
      value: 'Construction Pending',
    },
    { text: 'Closed - Lost', value: 'Closed/Lost' },
  ],
  closedate: [
    { text: 'Closes this calendar year', value: 'This Year' },
    { text: 'Closes next calendar year', value: 'Next Year' },
  ],
};

export const getGlobalFilters = () => {
  let contactRoles: ITextValue<string>[] = [];
  try {
    contactRoles = getContactRoles(arrToSnakeCase(getters.getListByCategory(store.state.Admin)('pipeline_roles').items) as ContactRolesEnum[]);
  } catch {
    contactRoles = [];
  }

  return {
    group: 'pipelines',
    model: false,
    isActive: false,
    icon: 'mdi-pipe',
    title: 'Pipelines',
    children: [
      {
        type: 'title',
        title: 'Pipeline Details'
      },
      {
        icon: 'mdi-adjust',
        title: i18n.t(LocalizationEnum.business_line),
        name: 'pipeline_business_line',
        type: 'business_line_switch',
      },
      {
        icon: 'mdi-adjust',
        title: 'Property Type',
        items: [],
        name: 'property_type',
        type: 'select',
        options: {
          icons: [
            'mdi-hospital-building',
            'mdi-hospital-building',
            'mdi-hospital-building',
            'mdi-hospital-building',
            'mdi-hospital-building',
            'mdi-hospital-building',
            'mdi-hospital-building',
            'mdi-hospital-building',
            'mdi-hospital-building',
          ]
        }
      },
      {
        icon: 'mdi-adjust',
        title: 'Transaction Category',
        items: getSelectItems().transactionCategories,
        name: 'pipeline_category',
        type: 'select',
      },
      {
        type: 'custom_size_filter',
        meta: {
          prefix: 'pipeline'
        },
        children: [
          {
            title: 'Available Space',
            name: 'pipeline_size',
            type: 'range'
          },
          {
            title: 'Land Size',
            name: 'pipeline_land_size',
            type: 'range'
          },
          {
            name: 'pipeline_land_size_unit',
            type: 'helper'
          },
          {
            title: 'Units / Keys',
            name: 'pipeline_units',
            type: 'range'
          }
        ]
      },
      {
        icon: 'mdi-adjust',
        title: 'Estimated Transaction Date',
        items: ['', ''],
        name: 'pipeline_close_date',
        type: 'date_range',
      },
      {
        title: 'Total Consideration',
        name: 'pipeline_consideration',
        type: 'range',
      },
      {
        title: 'In-House Gross',
        name: 'pipeline_inhouse_gross',
        type: 'range',
      },
      {
        title: 'Broker Gross',
        name: 'pipeline_broker_gross',
        type: 'range',
      },
      {
        title: 'Commission %',
        name: 'pipeline_commission_percentage',
        type: 'range',
      },

      {
        icon: 'mdi-adjust',
        title: 'Source Category',
        items: getSelectItems().sources,
        name: 'pipeline_source',
        type: 'select',
      },
      {
        icon: 'mdi-adjust',
        title: 'Pipeline Stage',
        items: pipelineStages.reduce((acc, stage, currentIndex) => {
          const item = { text: stage, value: currentIndex + 1 };

          return [...acc, item];
        }, [] as ITextValue<number>[]),
        name: 'pipeline_stage',
        type: 'select',
      },
      {
        icon: 'mdi-adjust',
        title: 'Broker',
        name: 'pipeline_broker',
        type: 'broker',
      },
      {
        icon: 'mdi-adjust',
        title: 'Contact Name',
        name: 'contact_names',
        type: 'contacts_combobox',
      },
      {
        icon: 'mdi-adjust',
        title: 'Contact Pipeline Role',
        items: contactRoles,
        name: 'pipeline_contact_role',
        type: 'select',
      },
      {
        type: 'title',
        title: 'Referrals'
      },
      {
        icon: 'mdi-adjust',
        title: 'Referral Send Type',
        name: 'pipeline_inbound_outbound',
        type: 'inbound_outbound_switch',
      },
      {
        icon: 'mdi-adjust',
        title: 'Referral Contact Keyword',
        items: ['', ''],
        name: 'pipeline_referral_keyword',
        type: 'textfield',
      },
      {
        type: 'divider'
      },
      {
        title: 'Created/Edited Date',
        items: [],
        name: 'pipeline_view_date',
        type: 'date_range',
      },
      {
        title: 'Created/Edited by',
        items: [
          { text: 'All users', _key: 'anyone' },
          { text: 'Me', _key: 'me' },
          { text: 'Me and my team', _key: 'myteam' },
          { text: 'Select user', _key: 'all' },
        ],
        name: 'pipeline_view',
        type: 'create_updated_select',
      },
      {
        title: 'Filtered Pipelines',
        name: 'filtered_items',
        type: 'filtered_items',
      },
    ],
  };
};
