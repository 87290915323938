export const getGlobalFilters = () => ({
  group: 'projects',
  model: false,
  isActive: false,
  icon: 'mdi-clipboard-text',
  title: 'Projects',
  children: [
    {
      type: 'divider'
    },
    {
      title: 'Created/Edited Date',
      items: [],
      name: 'project_view_date',
      type: 'date_range',
    },
    {
      title: 'Created/Edited by',
      items: [
        { text: 'All users', _key: 'anyone' },
        { text: 'Me', _key: 'me' },
        { text: 'Me and my team', _key: 'myteam' },
        { text: 'Select user', _key: 'all' },
      ],
      name: 'project_view',
      type: 'create_updated_select',
    },
    {
      title: 'Filtered Projects',
      name: 'filtered_items',
      type: 'filtered_items',
    },
  ]
});
