import Enterprise from '@/bundles/Account/models/Enterprise';

export default class EnterpriseToMapper {
  mapToModel (data: any): Enterprise {
    const enterprise = new Enterprise();
    enterprise.id = data.id;
    enterprise.name = data.name;
    enterprise.logo = data.logo;
    enterprise.domain = data.domain;
    return enterprise;
  }
}
