<template>
  <DashboardCard
    :expanded="cardExpanded"
    :loading="loading"
    :widget-type="widgetType"
    title="Agent Ranking"
    :count="brokerGrossArray.length"
    class="dashboard-card sc-broker-ranking"
    @update:expanded="$emit('update:expanded', $event)"
  >
    <template #actions>
      <div class="broker-ranking-card__buttons-wrapper">
        <div class="broker-ranking-card__buttons-group-item">
          <v-switch
            v-model="brokerGrossShowCommission"
            class="mr-5"
            label="Show $"
            hide-details
            small
          />
          <v-btn-toggle
            v-model="reportType"
            :class="{ 'mr-2': $vuetify.breakpoint.smAndUp }"
            mandatory
          >
            <v-btn small value="paid" class="grossButton py-4">Received</v-btn>
            <v-btn small value="gross" class="grossButton py-4">Booked</v-btn>
          </v-btn-toggle>
        </div>
        <div v-if="defaultStartDate">{{ date2 | formatDate }} - {{ date | formatDate }}</div>
        <div v-else class="broker-ranking-card__buttons-group-item">
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                class="mr-2"
                :small="$vuetify.breakpoint.xs"
                v-on="on"
              >
                <v-icon>mdi-calendar</v-icon> From: {{ date2 | formatDate }}
              </v-btn>
            </template>
            <v-date-picker v-model="date2" />
          </v-menu>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" :small="$vuetify.breakpoint.xs" v-on="on">
                <v-icon>mdi-calendar</v-icon>To: {{ date | formatDate }}
              </v-btn>
            </template>
            <v-date-picker v-model="date" />
          </v-menu>
          <v-select
            v-if="isAdmin"
            outlined
            label="Select Agent"
            dense
            hide-details
            :items="members"
            :loading="isMembersLoading"
            item-text="full_name"
            item-value="id"
            class="ml-1"
            style="max-width: 200px"
            clearable
            @change="changeUser"
          />
        </div>
      </div>
    </template>
    <template #content>
      <v-card flat>
        <v-overlay
          :value="loading"
          :opacity=".7"
          absolute
          color="white"
        >
          <div class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
        </v-overlay>
        <v-data-table
          :headers="brokerGrossHeaders"
          :items="brokerGrossArray"
          :single-expand="false"
          :expanded.sync="expanded"
          :items-per-page="-1"
          item-key="name"
          :sort-by="brokerGrossShowCommission ? 'brokerGross' : 'consideration'"
          :sort-desc="true"
          :mobile-breakpoint="0"
          class="elevation-1 sc-broker-ranking-report"
          hide-default-footer
        >
          <template #item="{ item, isExpanded, expand }">
            <tr :class="`${isExpanded ? 'expanded' : ''}`" class="sc-broker-ranking-broker" @click="expand(!isExpanded)">
              <td style="width: 80px"><v-chip small label dark>{{ item.ranking }}</v-chip></td>
              <td style="width: auto">{{ item.name }}</td>
              <td v-if="hasLocations" style="width: 180px">
                <div v-if="item.primary_location">{{ item.primary_location.name }}</div>
                <Label v-else />
              </td>
              <td v-if="hasAnnualGoal" style="width: 120px">
                <Label :value="getAnnualGoal(item.annual_goal)" />
              </td>
              <td style="width: 90px">{{ item.dealIds.length }}</td>
              <td style="width: 200px" class="text-right">{{ item.consideration | formatCurrency }}</td>
              <td v-if="brokerGrossShowCommission" style="width: 200px" class="text-right">
                <span>{{ item.brokerGross | formatCurrency }}</span>
              </td>
              <td v-if="brokerGrossShowCommission" style="width: 200px" class="text-right">
                <span v-if="reportType !== 'gross'">{{ item.brokerNet | formatCurrency }}</span>
              </td>
              <td v-if="brokerGrossShowCommission" style="width: 200px" class="text-right">
                <Label :value="getBrokerNet(item.brokerGross, item.brokerNet)" />
              </td>
              <td style="width: 200px" class="text-right"><BuildingSizeLabel :value="item.buildingSize" /></td>
              <td style="width: 180px" class="text-right"><LandSizeLabel :value="item.landSize" /></td>
              <td style="width: 24px">
                <v-btn
                  icon
                  small
                  @click.stop="exportToCsv(item)"
                >
                  <v-icon color="primary">mdi-file-export</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr class="broker-ranking-detail">
              <td :colspan="headers.length">
                <v-card outlined class="ma-2">
                  <BrokerRankingCardDetails
                    :item-entry="item"
                    :broker-gross-show-commission="brokerGrossShowCommission"
                    :report-type-entry="reportType"
                    @deal:show="showDeal"
                  />
                </v-card>
              </td>
            </tr>
          </template>
          <template #body.append>
            <tr class="sc-data-footer">
              <td colspan="2" />
              <td v-if="hasLocations" />
              <td v-if="hasAnnualGoal" />
              <td>
                {{ totalCount }}
              </td>
              <td class="text-right">
                {{ totalConsideration | formatCurrency }}
              </td>
              <td v-if="brokerGrossShowCommission" class="text-right">
                {{ totalBrokerGross | formatCurrency }}
              </td>
              <td v-if="brokerGrossShowCommission" class="text-right">
                <span v-if="reportType!=='gross'">{{ totalBrokerNet | formatCurrency }}</span>
              </td>
              <td v-if="brokerGrossShowCommission" class="text-right">
                <span v-if="reportType !== 'gross'">{{ totalBrokerNet2 }}</span>
              </td>
              <td class="text-right" style="width: 200px">
                <BuildingSizeLabel :value="totalBuildingSize" />
              </td>
              <td class="text-right" style="width: 180px">
                <LandSizeLabel :value="totalLandSize" />
              </td>
              <td class="broker-ranking-card__buttons-wrapper">
                <v-menu bottom left style="width: 300px;">
                  <template #activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon color="primary">mdi-file-export</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item :loading="isExportAllBrokersLoading" @click="exportAgentRankingData">
                      <v-list-item-content>
                        Agent Ranking
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item :loading="isExportAllBrokersLoading" @click="exportToCsvAllBrokers">
                      <v-list-item-content class="no-wrap">
                        Detailed Agent Ranking
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="printAgentRankingData"
                    >
                      <v-icon color="primary">mdi-file-document-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Agent Ranking PDF</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-snackbar
        v-model="dealPreview"
        right
        :timeout="-1"
        light
        class="pa-0"
      >
        <v-card style="position: relative">
          <v-btn
            absolute
            right
            icon
            style="z-index: 5;"
            top
            @click="closePreview"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <DealCard
            :item-id="dealPreviewId"
            :show-sumary="true"
            :allow-edit="false"
            :allow-link="true"
          />
        </v-card>
      </v-snackbar>
    </template>
  </DashboardCard>
</template>

<script>
import moment from 'moment';
import { memberService } from '@/bundles/Members/factory/memberServiceFactory';
import { mapMutations, mapState } from 'vuex';
import { concat } from 'lodash';
import { ServiceFactory } from '@/services/ServiceFactory';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';
import { ExportRecordType, ExportType } from '@/bundles/Common/types';

import DealCard from '@/components/common-components/recordCards/DealCard';
import BrokerRankingCardDetails from '@/bundles/Deals/components/BrokerRankingCardDetails';
import LandSizeLabel from '@/bundles/MeasurementUnits/components/LandSizeLabel.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';
import DashboardCard from '@/bundles/Dashboard/components/DashboardCard';
import Label from '@/bundles/Common/components/Label.vue';

const SavedSetService = ServiceFactory.get('savedSets');
const DealService = ServiceFactory.get('deals');

export default {
  name: 'BrokerRankingCard',

  components: {
    Label,
    DashboardCard,
    DealCard,
    BrokerRankingCardDetails,
    LandSizeLabel,
    BuildingSizeLabel
  },

  props: {
    defaultStartDate: {
      type: String,
      default: ''
    },
    defaultEndDate: {
      type: String,
      default: ''
    },
    itemKeys: {
      type: Array,
      default: () => []
    },
    defaultShowCommission: Boolean,
    cardExpanded: Boolean,
  },

  data: () => ({
    loading: true,
    menu: false,
    menu2: false,
    endDate: null,
    startDate: null,
    brokerGrossShowCommission: false,
    reportType: 'paid',
    brokerGrossArray: [],
    totalCount: null,
    totalConsideration: null,
    totalBuildingSize: null,
    totalLandSize: null,
    totalBrokerGross: null,
    totalBrokerNet: null,
    totalBrokerNet2: null, // lunt1ks todo: temp solution
    dealPreview: false,
    dealPreviewId: 0,
    expanded: [],
    members: [],
    memberId: null,
    isMembersLoading: false,
    isExportAllBrokersLoading: false,
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
      user: (state) => state.user
    }),
    isFinancialAdmin () {
      return this.user?.roles.some((role) => role.name === 'super admin' || role.name === 'financial admin');
    },
    widgetType () {
      return UserWidgetsEnum.broker_ranking;
    },
    date: {
      get () {
        const defaultDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

        return this.defaultEndDate || this.endDate || defaultDate;
      },
      set (value) {
        this.endDate = value
        this.menu = false
        this.getBrokerGross()
      }
    },
    date2: {
      get () {
        const defaultDate = `${moment().year()}-01-01`;

        return this.defaultStartDate || this.startDate || defaultDate;
      },
      set (value) {
        this.startDate = value
        this.menu2 = false
        this.getBrokerGross()
      }
    },
    hasLocations () {
      return this.brokerGrossArray.find(el => el.primary_location);
    },
    hasAnnualGoal () {
      return this.brokerGrossArray.find(el => el.annual_goal);
    },
    brokerGrossHeaders () {
      let headers = [
          { text: '#', value: 'ranking' },
          { text: 'Agent', value: 'name' },
        ];
      if (this.hasLocations) {
        headers = concat(headers, [{ text: 'Agent Primary Location', value: 'primary_location.address.street' }]);
      }
      if (this.hasAnnualGoal) {
        headers = concat(headers, [{ text: 'Annual Goal', value: 'annual_goal' }]);
      }
      if (this.brokerGrossShowCommission) {
        return concat(headers, [{ text: 'Deals', value: 'dealIds.length' },
          { text: 'Consideration', value: 'consideration', align: 'end' },
          { text: 'Agent Gross', value: 'brokerGross', align: 'end' },
          { text: 'Agent Net', value: 'brokerNet', align: 'end' },
          { text: 'Broker Net', value: 'brokerNet', align: 'end' },
          { text: 'Bldg Size', value: 'buildingSize', align: 'end' },
          { text: 'Land Size', value: 'landSize', align: 'end' },
          { text: 'Export', value: 'export' }]);
      }
      return concat(headers, [
        { text: 'Deals', value: 'dealIds.length' },
        { text: 'Consideration', value: 'consideration', align: 'end' },
        { text: 'Bldg Size', value: 'buildingSize', align: 'end' },
        { text: 'Land Size', value: 'landSize', align: 'end' },
        { text: 'Export', value: 'export' },
      ]);
    },
    requestParams () {
      const params = [
        { name: 'reportType', value: this.reportType },
        { name: 'filteredItems', value: this.itemKeys },
        { name: 'report_date', value: `${this.date2},${this.date}` }
      ];

      if (this.reportType === 'gross') {
        params.push({
          name: 'deal_close_transaction_date',
          value: `${this.date2},${this.date}`
        });
      }
      return params;
    },
  },

  watch: {
    reportType (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getBrokerGross()
      }
    }
  },

  created () {
    this.getBrokerGross();

    if (this.isAdmin) {
      this.getMembers();
    }
  },

  methods: {
    ...mapMutations('Notifications', ['ADD_NOTIFICATION']),
    async changeUser (value) {
      this.memberId = value;
      await this.getBrokerGross();
    },
    async getMembers () {
      try {
        this.isMembersLoading = true;

        const params = [
          { name: 'take', value: 0 },
          { name: 'status', value: 'active' },
        ];

        const { data } = await memberService.list(params);

        this.members = data.map(item => ({
          ...item,
          full_name: `${item.profile.first_name} ${item.profile.last_name}`
        })).sort((a, b) => (a.profile.last_name.localeCompare(b.profile.last_name)));
      } catch (error) {
        this.ADD_NOTIFICATION({ ...error });
      } finally {
        this.isMembersLoading = false;
      }
    },
    showDeal (deal) {
      this.dealPreviewId = deal._key
      this.dealPreview = true
    },
    closePreview () {
      this.dealPreviewId = 0
      this.dealPreview = false
    },
    async getBrokerGross () {
      try {
        this.loading = true;
        this.brokerGrossShowCommission = this.brokerGrossShowCommission ? this.brokerGrossShowCommission : this.defaultShowCommission;
        const { token, requestParams: params } = this;
        const { data } = await DealService.getBrokerGross({ params, token });
        this.brokerGrossArray = this.memberId ? data.brokers.filter(el => el.member === this.memberId) : data.brokers;
        this.totalConsideration = data.totalConsideration;
        this.totalCount = data.count;
        this.totalBuildingSize = data.totalBuildingSize;
        this.totalLandSize = data.totalLandSize;
        this.totalBrokerGross = data.totalInHouseGross;
        this.totalBrokerNet = data.totalInHouseNet;
        this.totalBrokerNet2 = this.getBrokerNet(this.totalBrokerGross, this.totalBrokerNet); // lunt1ks todo: temp solution
        this.$emit('updateTotals', data);
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        this.loading = false;
      }
    },
    getBrokerNet (brokerGross, brokerNet) { // lunt1ks todo: temp solution
      if (this.reportType === 'gross' || !isValuePresent(brokerGross)) {
        return null;
      }

      if (this.isFinancialAdmin) {
        const value = Number(brokerGross) - Number(brokerNet);
        return this.$options.filters.formatCurrency(value);
      }
    },
    sortBrokerGross (brokers) {
      this.brokerGrossArray = brokers.sort((b, a) => {
        const keyA = a.consideration
        const keyB = b.consideration

        return keyA - keyB
      })
    },
    saveFileFromBuffer (buffer, fileName) {
      const file = new Blob([new Uint8Array(buffer.data)], {
        type: 'application/octet-stream'
      });
      const FileSaver = require('file-saver');
      FileSaver.saveAs(file, fileName);
    },
    exportToCsv: async function (broker) {
      try {
        const response = await DealService.exportBrokerGross({
          token: this.token,
          params: this.requestParams,
          id: broker._key,
        });

        this.saveFileFromBuffer(response.data.buffer, `${broker.name}.csv`);
      } catch (error) {
        this.addNotification({ ...error });
      }
    },
    exportToCsvAllBrokers: async function () {
      try {
        this.isExportAllBrokersLoading = true;

        const response = await DealService.exportAllBrokersGross({
          token: this.token,
          params: [
            ...this.requestParams,
            { name: 'show_financials', value: this.brokerGrossShowCommission }
          ],
        });

        this.saveFileFromBuffer(response.data.buffer, 'Agent-Ranking.csv');
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.isExportAllBrokersLoading = false;
      }
    },
    getAnnualGoal (annualGoal) {
      return isValuePresent(annualGoal) ? this.$options.filters.formatCurrency(annualGoal) : null;
    },
    exportAgentRankingData: async function () {
      try {
        this.isExportAllBrokersLoading = true;

        const payload = {
          record_type: ExportRecordType.broker_gross,
          report_date: `${this.date2},${this.date}`,
          type: ExportType.list_export,
          show_financials: this.brokerGrossShowCommission,
          records: [],
          reportType: this.reportType
        };

        await SavedSetService.exportToExcelNew(payload);
        this.addNotification({
          status: 200,
          title: 'Success',
          message: 'Your export <b>is being generated</b>. When <b>complete</b>, a notification <b>will appear</b> at the <b>top right</b> of the screen. ' +
            'Some exports take a few minutes to generate.',
        });
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.isExportAllBrokersLoading = false;
      }
    },
    printAgentRankingData: function () {
      try {
        this.isExportAllBrokersLoading = true;

        const params = [
          { name: 'record_type', value: ExportRecordType.broker_gross },
          { name: 'report_date', value: `${this.date2},${this.date}` },
          { name: 'type', value: ExportType.list_export },
          { name: 'show_financials', value: this.brokerGrossShowCommission },
          { name: 'records', value: [] },
          { name: 'reportType', value: this.reportType },
        ];

        SavedSetService.printAgentRanking(this.token, params);
        this.addNotification({
          status: 200,
          title: 'Success',
          message: 'Your PDF <b>is being generated</b>. When <b>complete</b>, a notification <b>will appear</b> at the <b>top right</b> of the screen. ' +
            'Some PDF take a few minutes to generate.',
        });
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.isExportAllBrokersLoading = false;
      }
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.broker-ranking-card {
  height: auto !important;

  .v-toolbar__content {
    height: auto !important;
    flex-wrap: wrap;
  }
}

.broker-ranking-card__buttons-group-item {
  display: flex;
  align-items: center;

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 750px) {
    margin-bottom: 8px;
  }
}

.broker-ranking-card__buttons-wrapper {
  display: flex;
  align-items: center;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: 100%;
  }

  @media (max-width: 750px) {
    flex-wrap: wrap;
  }
}
</style>
