import { Nullable } from '@/utils/types';

export interface INotification {
  date: Nullable<string>;
  reminders: any[];
  // TODO sychdo: remove this when notification management works
  remindersToDisplay: any[];
}

export enum NotificationType {
  inside = 'inside',
  outside = 'outside'
}

export type IOccupier = any; // TODO sychdo: add proper type for occupier

export type IRentSchedule = any;

export enum OccupancyTypeEnum {
  lease = 'Leased',
  owner = 'Owner Occupied',
  pl3Operator = '3PL Operator',
  pl3Occupant = '3PL Occupant'
}
