import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';

export function removeNonMaskChars (mask: string): string {
  const regex = /[^\d#]/g;
  return mask.replace(regex, '').replace(/#/g, '#');
}

export function getMinMaxLabel (min, max) {
  if (!isValuePresent(min) && !isValuePresent(max)) {
      return 'N/A';
  }

  if (isValuePresent(min) && isValuePresent(max)) {
      return `${min} - ${max}`;
  }

  return `${min || 'N/A'}${max ? ` - ${max}` : ''}`;
}
